import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../utils/useIsMobile";
import JoinProcess from "../join/JoinProcess";
import ResetPasswordProcess from "../reset-password/ResetPasswordProcess";
import LoginForm from "./LoginForm";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { resetSend } from "../../store/send-code";

function LoginContainer() {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const route = pathname.split("/")[2];
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(resetSend());
  }, []);

  const openServicePolicy = (type: string) => {
    let url = "";
    switch (type) {
      default:
      case "servicePolicy":
        url = "https://goqual.notion.site/f9ed98fec1bd41c68fd860fd754c65e8";
        break;
      case "infoPolicy":
        url = "https://goqual.notion.site/94d85289a74d4169899058ccfe44b5ad";
        break;
    }

    window.open(url, "_blank");
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <div className="login-container">
          <div className="hejhome-login">
            <div className="hejhome-login-content">
              <img
                className="hejhome-square-logo"
                src={`${process.env.REACT_APP_S3_URL}/1.0/logos/HejhomeLoginLogo.png`}
                alt="square-logo"
              />
              {!route && isMobile && (
                <div className="hejhome-login-logo">
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/1.0/logos/HejhomeLoginLogo.png`}
                    alt="logo"
                  />
                </div>
              )}
              <Routes>
                <Route path="/" element={<LoginForm />} />
                <Route path="/signup" element={<JoinProcess />} />
                <Route
                  path="/reset-password"
                  element={<ResetPasswordProcess />}
                />
              </Routes>
            </div>
          </div>
          {/*<div className="background" />*/}
          {/*<div className="background crop" />*/}
        </div>
        <div className="site-main-layout-footer-content">
          <div className="site-layout-footer-content-info">
            <div className="service-name">
              <p>헤이홈 스퀘어</p>
            </div>
            <div className="service-detail">
              <div className="service-detail-company">
                <span>회사 : 주식회사 고퀄</span>
                <span>사업자 등록번호 : 514-81-92269</span>
              </div>
              <div className="service-detail-company">
                <span>대표 : 우상범</span>
                <span>통신판매업신고번호 : 제 2017-서울금천-0737호</span>
              </div>
              <div className="service-detail-address">
                <span>
                  주소 : 08503 서울특별시 금천구 가산디지털1로 165, 1001호,
                  1301호
                </span>
              </div>
              <div>
                <span>전화번호 : 1644-7098</span>
              </div>
              <div>
                <span>이메일 : square@goqual.com</span>
              </div>
              <div className="service-detail-policy-link">
                <p
                  className="service-policy"
                  onClick={() => {
                    openServicePolicy("servicePolicy");
                  }}
                >
                  서비스 이용약관
                </p>
                <p
                  className="service-info-policy"
                  onClick={() => {
                    openServicePolicy("infoPolicy");
                  }}
                >
                  개인정보처리방침
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="background" />
        <div className="background crop" />
      </div>
    </div>
  );
}

export default LoginContainer;
