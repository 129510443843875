import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import "moment/locale/ko";
import Battery from "../../assets/img/dashboard/battery";
import { useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { datetimeFormat } from "../../common/utils";

const SensorPanel = ({
  action,
  deviceType,
}: {
  action: any;
  deviceType?: string;
}) => {
  const [noDetected, setNoDetected] = useState(false);
  const { detail } = useSelector(panelDetailSelector);
  const { status, deviceName, spaceName, time, online } =
    detail as DeviceDetailType;
  const isDetectDevice = useMemo(() => {
    return (
      deviceType === "sensorWater" ||
      deviceType === "sensorSmoke" ||
      deviceType === "sensorGas"
    );
  }, [deviceType]);

  const eventTime = useMemo(() => {
    return moment(time).format(datetimeFormat);
  }, [time]);

  const setHeader = () => {
    switch (deviceType) {
      case "sensorWater":
      case "sensorSmoke":
      case "sensorGas":
      case "sensorDo":
        return "현재 상태";
      case "sensorMo":
        return "마지막 감지";
    }
  };

  const setDetectContent = () => {
    switch (deviceType) {
      case "sensorWater":
      case "sensorSmoke":
      case "sensorGas":
        return status?.alarm ? "감지" : "정상";
      case "sensorDo":
        return status?.alarm ? "열림" : "닫힘";
      case "sensorMo":
        return !noDetected ? "동작 없음" : eventTime.slice(13);
    }
  };

  useEffect(() => {
    if (status && status.alarm && !noDetected && time) {
      setNoDetected(true);
    }
  }, [status?.alarm, time]);

  return (
    <section className="device-setting-body alarm-wrap">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <Battery batteryLevel={status?.battery} />
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div
        className={`device-display alarm status ${
          status?.alarm && isDetectDevice ? "alert" : ""
        }`}
      >
        <h2>{setHeader()}</h2>
        <div className="display">
          <h1
            className={
              status?.alarm
                ? "detected-title"
                : isDetectDevice
                ? "normal"
                : "undetected-title"
            }
          >
            {setDetectContent()}
          </h1>
          <p className="detected-time-stamp">
            {!noDetected
              ? "없음"
              : deviceType === "sensorMo"
              ? eventTime.slice(0, 13)
              : `${eventTime} 확인`}
          </p>
        </div>
      </div>
    </section>
  );
};

export default SensorPanel;
