import React, { useEffect, useState } from "react";
import { Button, Divider, Card, Tabs, Popover } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { updateAirposModalVisible } from "../../../store/setting/airpos";
import { updateToastcamModalVisible } from "../../../store/setting/toascam";
import { updatePaymentModalVisible } from "../../../store/setting/payment";
import {
  fetchGetUserMembershipHistory,
  settingChangeuserMembershipSelector,
  settingMembershipHistorySelector,
  updateChangeUsersMembershipVisible,
  updateNowUserMembership,
  updateRollbackMembershipVisible,
} from "../../../store/setting/user-membership";
import AirposModal from "./airpos/AirposModal";
import AirposDeleteModal from "./airpos/AirposDeleteModal";
import ToastCamModal from "./toastcam/ToastCamModal";
import TossPaymentModal from "./payment/TossPayment";
import AddPaymentModal from "./payment/AddPayment";
import AddPaymentCompleteModal from "./payment/AddPaymentComplete";
import ChangeMembershipModal from "./payment/ChangeMembership";
import RollbackMembershipModal from "./payment/RollbackMembership";
import PaymentHistoryModal from "./paymentHistory/PaymentHistoryModal";
import EditPaymentMethodModal from "./payment/EditPaymentMethod";

import {
  fetchGetSyncPlatformList,
  settingSyncPlatformListSelector,
} from "../../../store/setting/account/syncPlatforms";
import {
  resetAirposDeleteModalVisible,
  settingAirposDeleteSelector,
  updateAirposDeleteModal,
} from "../../../store/setting/airpos/delete";
import {
  resetToastcamDeleteModalVisible,
  settingToastcamDeleteSelector,
  updateToastcamDeleteModal,
} from "../../../store/setting/toascam/delete";
import ToastCamDeleteModal from "./toastcam/ToastCamDeleteModal";
import { updateAddPaymentModalVisible } from "../../../store/setting/payment/add";
import { updateAddPaymentCompleteModalVisible } from "../../../store/setting/payment/addComplete";
import { loginSelector } from "../../../store/login";
import { updatePaymentHistoryModalVisible } from "../../../store/setting/payment/history";
import {
  getPaymentMethodLists,
  postDeletePaymentMethod,
} from "../../../api/payment/payment";
import {
  setUpdatePaymentMethodId,
  updateUpdatePaymentMethodModalVisible,
} from "../../../store/setting/payment/edit";
import moment from "moment";

type paymentMethods = {
  id: string;
  goqual_user_info_id: string;
  cardNumber: string;
  cardCompany: string;
  priority: number;
  isValid: string;
  created_at: string;
  updated_at: string;
};

function PeristalsisInfo() {
  const dispatch = useDispatch<AppDispatch>();
  const { success: airposDeleteSuccess } = useSelector(
    settingAirposDeleteSelector
  );
  const { success: toastcamDeleteSuccess } = useSelector(
    settingToastcamDeleteSelector
  );
  const { userMembershipHistory } = useSelector(
    settingMembershipHistorySelector
  );

  const syncPlatforms = useSelector(settingSyncPlatformListSelector);
  const { service } = useSelector(loginSelector);
  const {
    visible: changeMembershipModalVisible,
    rollbackMembershipModalVisible,
  } = useSelector(settingChangeuserMembershipSelector);

  const [selected, setSelected] = useState("membership");
  const [userMembership, setUserMembership] = useState(service);
  const [paymentMethods, setPaymentMethods] = useState<paymentMethods[]>([]);

  useEffect(() => {
    dispatch(fetchGetUserMembershipHistory());
  }, []);

  useEffect(() => {
    if (!changeMembershipModalVisible || !rollbackMembershipModalVisible)
      dispatch(fetchGetUserMembershipHistory());
  }, [changeMembershipModalVisible, rollbackMembershipModalVisible]);

  useEffect(() => {
    if (airposDeleteSuccess || toastcamDeleteSuccess) {
      dispatch(fetchGetSyncPlatformList());
      dispatch(resetAirposDeleteModalVisible());
      dispatch(resetToastcamDeleteModalVisible());
    }
  }, [airposDeleteSuccess, toastcamDeleteSuccess]);

  useEffect(() => {
    if (selected === "payment") getPaymentMethods();
  }, [selected]);

  const getPaymentMethods = async () => {
    const { success, result } = await getPaymentMethodLists();

    if (!success) return;
    setPaymentMethods(result);
  };

  const isSyncPlatform = (platformType: string) => {
    const { syncPlatformList } = syncPlatforms;
    return syncPlatformList.find(
      ({ syncPlatformInfo }: { syncPlatformInfo: { platform_name: string } }) =>
        syncPlatformInfo.platform_name === platformType
    );
  };

  const showChangeMembershipModal = () => {
    const { memberShipChangeSchedule } = userMembershipHistory;
    if (memberShipChangeSchedule.length > 0) {
      dispatch(updateRollbackMembershipVisible(true));
    } else {
      dispatch(updateNowUserMembership(userMembership));
      dispatch(updateChangeUsersMembershipVisible(true));
    }
  };

  const Membership = () => {
    const MembershipSchedule = () => {
      const { memberShipChangeSchedule, memberShipPaymentSchedule } =
        userMembershipHistory;

      if (memberShipPaymentSchedule.length > 0) {
        const paymentSchedule = memberShipPaymentSchedule.find((list) => {
          if (moment().isBefore(list.schedule)) return list;
        });

        if (paymentSchedule)
          return (
            <p className="membership-schedule">
              {moment(paymentSchedule.schedule).format("YYYY년 MM월 DD일")} 갱신
              예정
            </p>
          );
      }

      if (memberShipChangeSchedule.length > 0) {
        const changeMemberShipSchedule = memberShipChangeSchedule.find(
          (list) => {
            if (moment().isBefore(list.schedule)) return list;
          }
        );

        if (changeMemberShipSchedule) {
          return (
            <p className="membership-schedule">
              <span className="membership-expire">
                {moment(changeMemberShipSchedule.schedule).format(
                  "YYYY년 MM월 DD일"
                )}
              </span>
              만료 예정
            </p>
          );
        }
      }

      return null;
    };

    const MembershipChangeInfoTitle = () => {
      const { memberShipChangeSchedule, memberShipPaymentSchedule } =
        userMembershipHistory;

      if (memberShipPaymentSchedule.length > 0)
        return <p className="membership-change-title">멤버십 변경</p>;

      if (memberShipChangeSchedule.length > 0)
        return (
          <div>
            <p className="membership-change-title">멤버십 변경 철회</p>
            <p className="membership-change-title-desc">
              헤이홈 스퀘어 PRO가 만료되기전 까지 멤버십 변경을 철회할 수
              있어요.
            </p>
          </div>
        );

      return null;
    };

    return (
      <>
        <Card className="membership-card">
          <p className="membership-card-title">현재 멤버십</p>
          <div className="membership-badge">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/user/${
                userMembership === "pro" ? "Pro.png" : "Basic.png"
              }`}
              alt="membership-badge-icon"
            />
          </div>
          <div className="membership-info">
            <p className="membership-product">
              헤이홈 스퀘어 {userMembership.toUpperCase()}
            </p>
            {userMembership === "pro" && <MembershipSchedule />}
          </div>
        </Card>
        <div
          className="membership-change"
          onClick={() => {
            showChangeMembershipModal();
          }}
        >
          <div className="membership-change-info">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/user/Upgrade.png`}
              alt="membership-change-icon"
            />
            {userMembership === "basic" && (
              <p className="membership-change-title">멤버십 변경</p>
            )}
            {userMembership === "pro" && <MembershipChangeInfoTitle />}
            {/*<p className="membership-change-title">멤버십 변경</p>*/}
          </div>
          <div className="membership-change-info-arrow">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/user/Upgrade_arrow.png`}
              alt="membership-change-icon"
            />
          </div>
        </div>
      </>
    );
  };

  const SyncCCTV = () => {
    const syncInfo = isSyncPlatform("ToastCam");

    if (service === "basic") {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/basic_cctv_icon.png`}
              alt="cctv_icon"
            />
            <div className="setting-info-basic">
              <h1>CCTV</h1>
              <p>든든하고 실속있는 CCTV로 내 매장 한 눈에 살펴보기</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pro_cctv_icon.png`}
              alt="cctv_icon"
            />
            <div className="setting-info">
              <h1>CCTV</h1>
              <p>{syncInfo ? `연동됨 - ${syncInfo.user_id}` : null}</p>
            </div>
            {syncInfo ? (
              <Button
                type="default"
                onClick={() => dispatch(updateToastcamDeleteModal(true))}
              >
                연동 해제
              </Button>
            ) : (
              <Button
                type="default"
                onClick={() => {
                  dispatch(updateToastcamModalVisible(true));
                }}
              >
                연동
              </Button>
            )}
          </div>
        </>
      );
    }
  };

  const SyncPos = () => {
    const syncInfo = isSyncPlatform("AirPos");

    if (service === "basic") {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/basic_airporce_icon.png`}
              alt="airpos_icon"
            />
            <div className="setting-info-basic">
              <h1>에어포스</h1>
              <p>매출 현황과 통계를 한눈에</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pro_airporce_icon.png`}
              alt="airpos_icon"
            />
            <div className="setting-info">
              <h1>에어포스</h1>
              <p>{syncInfo ? `연동됨 - ${syncInfo.user_id}` : null}</p>
            </div>
            {syncInfo ? (
              <Button
                type="default"
                onClick={() => dispatch(updateAirposDeleteModal(true))}
              >
                연동 해제
              </Button>
            ) : (
              <Button
                type="default"
                onClick={() => {
                  dispatch(updateAirposModalVisible(true));
                }}
              >
                연동
              </Button>
            )}
          </div>
        </>
      );
    }
  };

  const SyncKko = () => {
    const syncInfo = isSyncPlatform("Kko");

    if (service === "basic") {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/basic_kko_icon.png`}
              alt="kko_icon"
            />
            <div className="setting-info-basic">
              <h1>알림톡</h1>
              <p>실시간 매장 이벤트를 알림톡으로</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Divider />
          <div className="setting-item">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pro_airporce_icon.png`}
              alt="kko_icon"
            />
            <div className="setting-info">
              <h1>알림톡</h1>
              {syncInfo
                ? `연동됨 - ${syncInfo.user_id}`
                : "실시간 매장 이벤트를 알림톡으로"}
            </div>
            {syncInfo ? (
              <Button type="default">연동 해제</Button>
            ) : (
              <Button type="default">관리</Button>
            )}
          </div>
        </>
      );
    }
  };

  const ManagePayment = () => {
    return (
      <>
        <Divider />
        <div
          className="setting-item"
          onClick={() => {
            dispatch(updatePaymentHistoryModalVisible(true));
          }}
        >
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/user/PaymentHistory.png`}
            alt="paymentHistory_icon"
          />
          <div className="setting-info">
            <h1>결제 내역</h1>
          </div>
          <div>
            <RightOutlined />
          </div>
        </div>
      </>
    );
  };

  const AddPayment = () => {
    return (
      <>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/user/PaymentMethod.png`}
            alt="paymentMethod_icon"
          />
          <div className="setting-info">
            <h1>새 결제수단 추가</h1>
            <p>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/user/PaymentMethodWarning.png`}
                alt="payment_warning"
              />
              잔액을 충전할 수 있는 결제 수단을 등록합니다.
            </p>
          </div>
          <div onClick={showAddTossPayments}>
            <Button type="default">추가</Button>
          </div>
        </div>
      </>
    );
  };

  const showAddTossPayments = () => {
    dispatch(updateAddPaymentModalVisible(true));
  };

  const competedAddPayments = () => {
    dispatch(updateAddPaymentModalVisible(false));
    dispatch(updateAddPaymentCompleteModalVisible(true));
  };

  const deletePaymentMethod = async (paymentMethodId: string) => {
    await postDeletePaymentMethod(paymentMethodId);
    await getPaymentMethods();
  };

  const showEditPaymentMethod = (paymentMethodId: string) => {
    dispatch(setUpdatePaymentMethodId(paymentMethodId));
    dispatch(updateUpdatePaymentMethodModalVisible(true));
  };

  const paymentMethodManageContent = (paymentMethodId: string) => {
    return (
      <div className="payment-popover">
        <p
          className="payment-popover-mange"
          onClick={() => {
            showEditPaymentMethod(paymentMethodId);
          }}
        >
          결제 수단 관리
        </p>
        <Divider />
        <p
          className="payment-popover-delete"
          onClick={() => {
            deletePaymentMethod(paymentMethodId);
          }}
        >
          결제 수단 삭제
        </p>
      </div>
    );
  };

  const MyPaymentMethods = () => {
    return (
      <>
        <Divider style={{ marginTop: "16px" }} />
        {paymentMethods.map(({ id, cardNumber, cardCompany, priority }) => {
          return (
            <div key={id}>
              <div className="setting-item">
                <img
                  src={`${process.env.REACT_APP_S3_URL}/1.0/user/Payment_Card.png`}
                  alt="payment_card"
                />
                <div className="setting-info">
                  <h1>
                    {cardCompany} {cardNumber}
                  </h1>
                  <p>신용카드</p>
                </div>
                <div className="payment-order">
                  <p>{priority === 1 ? "주 결제 수단" : "보조 결제 수단"}</p>
                </div>
                {priority !== 1 && (
                  <Popover
                    content={paymentMethodManageContent(id)}
                    trigger="click"
                  >
                    <div className="payment-edit">
                      <img
                        src={`${process.env.REACT_APP_S3_URL}/1.0/user/EditPaymentMethod.png`}
                        alt="payment_warning"
                      />
                    </div>
                  </Popover>
                )}
              </div>
              <Divider />
            </div>
          );
        })}
      </>
    );
  };

  const onClickTab = (tabType: string) => {
    setSelected(tabType);
  };

  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>서비스 관리</h1>
      </div>
      <div className="setting-tabs">
        <div
          className={
            selected === "membership" ? "membershipTab-On" : "membershipTab-Off"
          }
          onClick={() => {
            onClickTab("membership");
          }}
        >
          <span className="membershipTab-title">멤버십 및 서비스</span>
        </div>
        <div
          className={
            selected === "payment" ? "paymentTab-On" : "paymentTab-Off"
          }
          onClick={() => {
            onClickTab("payment");
          }}
        >
          <span className="paymentTab-title">결제 관리</span>
        </div>
      </div>
      <div className="setting-content">
        {selected === "membership" && (
          <>
            <p>멤버십</p>
            <Membership />
            <p>부가 서비스</p>
            <Divider />
            <SyncCCTV />
            <SyncPos />
            <SyncKko />
            <Divider />
            <Divider />
          </>
        )}
        {selected === "payment" && (
          <>
            <p>결제 관리</p>
            <ManagePayment />
            <AddPayment />
            <Divider />
            <p />
            <p>내 결제 수단</p>
            <span className="payment-method-warning">
              만약 주 결제 수단으로 결제할 수 없다면, 자동으로 보조 결제
              수단으로 결제를 시도합니다.
            </span>
            <MyPaymentMethods />
          </>
        )}
      </div>
      <AirposModal />
      <AirposDeleteModal />
      <ToastCamDeleteModal />
      <ToastCamModal />
      <TossPaymentModal />
      <AddPaymentModal completedCallback={competedAddPayments} />
      <AddPaymentCompleteModal completedCallback={getPaymentMethods} />
      <ChangeMembershipModal />
      <RollbackMembershipModal />
      <PaymentHistoryModal />
      <EditPaymentMethodModal completedCallback={getPaymentMethods} />
    </div>
  );
}
export default PeristalsisInfo;
