import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { joinSelector } from "../../../store/join";
import {
  emailVerifySelector,
  fetchEmailVerify,
} from "../../../store/verify-email";
import useQuery from "../../../utils/useQuery";
import EmailForm from "../../common/email-form/EmailForm";
import { Button } from "antd";

function JoinStep1() {
  const { form } = useSelector(joinSelector);
  const { valid, loading } = useSelector(emailVerifySelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    valid !== undefined && valid && navigate(`/login/signup?step=${step + 1}`);
  }, [valid]);

  const onNextClick = useCallback(async () => {
    await dispatch(fetchEmailVerify(form.email as string));
  }, [form.email, dispatch]);

  return (
    <div className="join-step1">
      <EmailForm />
      <div className="join-footer">
        <Button
          type="primary"
          disabled={!form.email || form.email === "" || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
    </div>
  );
}

export default JoinStep1;
