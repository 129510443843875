import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type addPaymentState = {
    visible: boolean;
};

const initialState: addPaymentState = {
    visible: false,
};

const slice = createSlice({
    name: "setting/addPayment/main",
    initialState,
    reducers: {
        resetAddPaymentModalVisible: (state: addPaymentState) => ({
            ...state,
            visible: initialState.visible,
        }),
        updateAddPaymentModalVisible: (state: addPaymentState, { payload }) => ({
            ...state,
            visible: payload,
        }),
    },
});

export const settingAddPaymentSelector = (state: RootState) =>
  state.setting.payment.add;

export const { resetAddPaymentModalVisible, updateAddPaymentModalVisible } =
    slice.actions;

export default slice;
