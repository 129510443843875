import React, { useEffect, useState } from "react";
import { Button, Modal, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  settingChangeuserMembershipSelector,
  updateRollbackMembershipVisible,
} from "../../../../store/setting/user-membership";
import { postMembershipChangeCancel } from "../../../../api/user/user";

function ChangeMembershipModal() {
  const { rollbackMembershipModalVisible } = useSelector(
    settingChangeuserMembershipSelector
  );
  const dispatch = useDispatch<AppDispatch>();

  const onCancel = () => {
    dispatch(updateRollbackMembershipVisible(false));
  };

  const onOk = async () => {
    await postMembershipChangeCancel();
    dispatch(updateRollbackMembershipVisible(false));
  };

  return (
    <Modal
      className="change-membership-confirm"
      visible={rollbackMembershipModalVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="change-membership-cancel"
          className="change-membership-cancel"
          onClick={onCancel}
        >
          취소
        </Button>,
        <Button
          key="change-membership-ok"
          className="change-membership-ok"
          onClick={onOk}
        >
          철회
        </Button>,
      ]}
    >
      <div className="change-membership-confirm-content">
        <div className="content-title">
          <p>
            멤버십 변경을 <br /> 철회하시겠어요?
          </p>
        </div>
        <div className="content-text">
          <p>
            BASIC 멤버십으로 변경을 철회하면
            <br />
            PRO 멤버십이 계속 유지되요. 정말로 멤버십 <br />
            변경을 철회하시겠어요?
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeMembershipModal;
