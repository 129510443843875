import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  settingChangeuserMembershipSelector,
  resetChangeuserMembershipVisible,
  updateChangeUsersMembershipVisible,
} from "../../../../store/setting/user-membership";
import { AppDispatch } from "../../../../store";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { updatePaymentModalVisible } from "../../../../store/setting/payment";
import { updateConfirmModalVisible } from "../../../../store/setting/payment/confirm";
import { loginSelector } from "../../../../store/login";
import { getPaymentMethodLists } from "../../../../api/payment/payment";
import { dashboardListSelector } from "../../../../store/dashboard-manage/list";
import { spaceListSelector } from "../../../../store/space/list";
import { postMembershipUpdate } from "../../../../api/user/user";

type paymentMethods = {
  id: string;
  goqual_user_info_id: string;
  cardCompany: string;
  cardNumber: string;
  priority: number;
  isValid: string;
  created_at: string;
  updated_at: string;
};

function ChangeTierModal() {
  const [checkChangeMembership, setCheckChangeMembership] = useState(false);
  const [checkMonthlyPay, setCheckMonthlyPay] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<paymentMethods[]>([]);
  // const [checkCollectInfo, setCheckCollectInfo] = useState(false);

  const [remainDashboardId, setRemainDashboardId] = useState(0);
  const [remainSpaceId, setRemainSpaceId] = useState(0);

  const { visible } = useSelector(settingChangeuserMembershipSelector);
  const { service } = useSelector(loginSelector);
  const { dashboardList } = useSelector(dashboardListSelector);
  const { spaceList } = useSelector(spaceListSelector);

  const dispatch = useDispatch<AppDispatch>();

  // get user payment schedule day
  const TEMP_PAY_DAY = "2023-04-29";

  useEffect(() => {
    if (!visible) {
      setCheckChangeMembership(false);
      setCheckMonthlyPay(false);
      // setCheckCollectInfo(false);
      dispatch(resetChangeuserMembershipVisible());
    } else {
      getPaymentMethods();
    }
  }, [visible]);

  useEffect(() => {
    if (service.toLowerCase() === "pro") {
      if (dashboardList.length > 0) setRemainDashboardId(dashboardList[0].id);
      if (spaceList.length > 0) setRemainSpaceId(spaceList[0].id);
    }
  }, [dashboardList, spaceList]);

  const getPaymentMethods = async () => {
    const { success, result } = await getPaymentMethodLists();

    if (!success) return;
    setPaymentMethods(result);
  };

  const onCancel = () => {
    dispatch(updateChangeUsersMembershipVisible(false));
  };

  const changeMemberShip = async () => {
    if (service.toLowerCase() === "basic") {
      dispatch(updatePaymentModalVisible(true));
    } else if (service.toLowerCase() === "pro") {
      // dispatch(updateConfirmModalVisible(true));
      await postMembershipUpdate({
        remainDashboardId: remainDashboardId,
        remainSpaceId: remainSpaceId,
        schedule: moment(TEMP_PAY_DAY).add(1, "day").format("YYYY-MM-DD"),
      });

      // dispatch(updateConfirmModalVisible(false));
      dispatch(updateChangeUsersMembershipVisible(false));
    }
  };

  const isPossibleChangeMembership = () => {
    // return !checkChangeMembership;
    // return !checkMonthlyPay;

    return !checkChangeMembership;
  };

  const onChangeCheckedChangeMembership = (
    checkType: string,
    e: CheckboxChangeEvent
  ) => {
    switch (checkType) {
      case "all":
        setCheckChangeMembership(!e.target.value);
        setCheckMonthlyPay(!e.target.value);
        // setCheckCollectInfo(!e.target.value);
        break;

      case "checkMonthlyPay":
        if (e.target.value) setCheckChangeMembership(false);
        setCheckMonthlyPay(!e.target.value);
        break;

      case "checkCollectInfo":
        if (e.target.value) setCheckChangeMembership(false);
        // setCheckCollectInfo(!e.target.value);
        break;

      case "basic":
        setCheckChangeMembership(!e.target.value);
        break;

      default:
        break;
    }
  };

  const onChangeRemainDashboard = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRemainDashboardId(Number(e.target.value));
  };

  const onChangeRemainSpace = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRemainSpaceId(Number(e.target.value));
  };

  const UserMembershipDesc = () => {
    switch (service.toLowerCase()) {
      case "basic":
        return (
          <div className="change-membership-badge-pro">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/user/pro_tier.png`}
              alt="membership-badge-icon"
              style={{ marginLeft: "-32px" }}
            />
          </div>
        );
        break;
      case "pro":
        return (
          <>
            <div className="change-membership-badge-basic">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/user/basic_tier.png`}
                alt="membership-badge-icon"
              />
            </div>
            <div className="change-membership-desc-detail">
              <p className="change-membership-desc-detail-01">
                헤이홈 스퀘어의 기본 기능을 사용할 수 있는 멤버십이에요.
              </p>
              <p className="change-membership-desc-detail-02">
                멤버십을 변경하면 PRO 멤버십에서 사용하던 기능이
                {moment(TEMP_PAY_DAY).add(1, "day").format("YYYY년 MM월 DD일")}
                부터 아래와 같이 제한돼요.
              </p>
            </div>
          </>
        );
        break;
      default:
        return null;
        break;
    }
  };

  const MembershipContent = () => {
    switch (service.toLowerCase()) {
      default:
      case "basic":
        return (
          <div className="change-membership-contents">
            <div className="membership-functions">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/user/pro_functions.png`}
                alt="membership-pro-functions"
              />
            </div>
            <div className="membership-check-list">
              <div className="membership-payment-method">
                <p className="membership-payment-method-title">결제수단</p>
                <select>
                  {paymentMethods.map(
                    ({ id, cardCompany, cardNumber, priority }) => {
                      return (
                        <option key={id}>
                          {cardCompany} {cardNumber}
                          {priority === 1 ? " / 주 결제수단" : ""}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="membership-payment-agrees">
                <div className="agree-all">
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/checkIcon.png`}
                    alt="check-icon"
                  />
                  <p>결제 유의사항 확인(필수)</p>
                  <Checkbox
                    className="agree-checkbox"
                    value={checkMonthlyPay}
                    checked={checkMonthlyPay}
                    onChange={(e) => {
                      onChangeCheckedChangeMembership("checkMonthlyPay", e);
                    }}
                  />
                </div>
                <div className="payment-desc">
                  <p>
                    <span>
                      본 상품은 1회성 결제로 15,000원의 결제가 이루어집니다.
                    </span>
                    <span>
                      결제수단을 추가하지 않는 경우 추가적으로 결제를 요청하지
                      않습니다.
                    </span>
                    <span>&nbsp;</span>
                    <span>
                      결제수단을 추가하여 매월 정기 결제에 동의한 분들에 한해
                      자동으로
                    </span>
                    <span>결제가 진행되어 PRO멤버십이 유지됩니다.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "pro":
        return (
          <div className="change-membership-contents">
            <div className="membership-functions">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/user/basic_functions.png`}
                alt="membership-pro-functions"
              />
            </div>
            <div className="membership-check-list">
              <div className="membership-functions">
                <img
                  src={`${process.env.REACT_APP_S3_URL}/1.0/user/basic_functions_2.png`}
                  alt="membership-pro-functions"
                />
              </div>
              <div className="membership-remain-dashboard">
                <p className="membership-remain-dashboard-title">
                  유지할 대시보드
                </p>
                <select
                  onChange={onChangeRemainDashboard}
                  defaultValue={remainDashboardId}
                >
                  {dashboardList.map(({ id, dashboardName }) => {
                    return (
                      <option key={id} value={id}>
                        {dashboardName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="membership-remain-space">
                <p className="membership-remain-space-title">유지할 장소</p>
                <select
                  onChange={onChangeRemainSpace}
                  defaultValue={remainSpaceId}
                >
                  {spaceList.map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="remain-desc">
                <p>삭제되는 장소의 기기는 '장소 미지정'으로 이관됩니다.</p>
              </div>
              <div className="agree-remain">
                <p>정말로 멤버십을 변경하시겠어요?</p>
                <Checkbox
                  className="agree-checkbox"
                  value={checkChangeMembership}
                  checked={checkChangeMembership}
                  onChange={(e) => {
                    onChangeCheckedChangeMembership("basic", e);
                  }}
                />
              </div>
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <Modal
      className="change-membership"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="change-membership-cancel"
          onClick={onCancel}
          type="default"
          className="change-membership-cancel"
        >
          취소
        </Button>,
        <Button
          key="change-membership-ok"
          type="primary"
          className="change-membership-ok"
          disabled={isPossibleChangeMembership()}
          onClick={changeMemberShip}
        >
          멤버십 변경
        </Button>,
      ]}
    >
      <div className="change-membership-title">
        <h1>멤버십 변경</h1>
      </div>
      <div className="change-membership-desc">
        <UserMembershipDesc />
      </div>
      <MembershipContent />
    </Modal>
  );
}

export default ChangeTierModal;
