import React from "react";
import { Input, Modal } from "antd";
import "./SettingContent.scss";

interface popupProps {
  visible: boolean;
  onClickOk: any;
  onClickCancel: any;
}

export function ChangePayMethodPopup(props: popupProps) {
  return (
    <Modal
      visible={props.visible}
      closable={false}
      className="Change-PayMethod-Modal"
      footer={[
        <button
          key={"cancelBtn"}
          className="Change-PayMethod-Button-Cancel"
          onClick={props.onClickCancel}
        >
          취소
        </button>,
        <button
          key={"okBtn"}
          className="Change-PayMethod-Button-Ok"
          onClick={props.onClickOk}
        >
          변경
        </button>,
      ]}
      width={480}
    >
      <h1>지불수단 변경</h1>
      <p>새로운 지불 정보를 입력해주세요.</p>
      <div
        style={{
          border: "solid 1px #797672",
          borderRadius: "16px",
          height: "66px",
        }}
      >
        <p
          style={{
            marginTop: "12px",
            marginLeft: "16px",
            marginBottom: 0,
            fontSize: "12px",
            fontWeight: "700",
          }}
        >
          카드번호
        </p>
        <Input.Password
          bordered={false}
          placeholder={"카드 번호를 입력해주세요."}
        />
      </div>
      <div style={{ display: "inline-flex", marginTop: "16px" }}>
        <div
          style={{
            border: "solid 1px #797672",
            borderRadius: "16px",
            height: "66px",
          }}
        >
          <p
            style={{
              marginTop: "12px",
              marginLeft: "16px",
              marginBottom: 0,
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            만료일
          </p>
          <Input.Password bordered={false} placeholder={"MM/YY"} />
        </div>
        <div
          style={{
            border: "solid 1px #797672",
            borderRadius: "16px",
            marginLeft: "10px",
            height: "66px",
          }}
        >
          <p
            style={{
              marginTop: "12px",
              marginLeft: "16px",
              marginBottom: 0,
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            보안코드
          </p>
          <Input.Password bordered={false} placeholder={"CVC"} />
        </div>
      </div>
      <div
        style={{
          border: "solid 1px #797672",
          borderRadius: "16px",
          marginTop: "16px",
          height: "66px",
        }}
      >
        <p
          style={{
            marginTop: "12px",
            marginLeft: "16px",
            marginBottom: 0,
            fontSize: "12px",
            fontWeight: "700",
          }}
        >
          이름
        </p>
        <Input
          bordered={false}
          placeholder={"카드 소유자 본인의 이름을 입력해주세요."}
          maxLength={10}
        />
      </div>
      <div
        style={{
          border: "solid 1px #797672",
          borderRadius: "16px",
          marginTop: "16px",
          height: "66px",
        }}
      >
        <p
          style={{
            marginTop: "12px",
            marginLeft: "16px",
            marginBottom: 0,
            fontSize: "12px",
            fontWeight: "700",
          }}
        >
          청구 주소
        </p>
        <Input
          bordered={false}
          placeholder={"카드 소유자 본인의 청구 주소를 입력해주세요."}
          maxLength={128}
        />
      </div>
      <div
        style={{
          border: "solid 1px #797672",
          borderRadius: "16px",
          marginTop: "16px",
          height: "66px",
        }}
      >
        <p
          style={{
            marginTop: "12px",
            marginLeft: "16px",
            marginBottom: 0,
            fontSize: "12px",
            fontWeight: "700",
          }}
        >
          청구 메일
        </p>
        <Input
          bordered={false}
          placeholder={"청구 정보를 받을 이메일을 입력해주세요."}
          maxLength={128}
        />
      </div>
    </Modal>
  );
}
