import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, openModal, updateJoinForm } from "../../../store/join";
import useIsMobile from "../../../utils/useIsMobile";
import { getTerm } from "../../../api/join/join";
import { Button, Drawer, Modal } from "antd";

function JoinTerms() {
  const isMobile = useIsMobile();

  const { modalOpen } = useSelector(joinSelector);
  const { open, type } = modalOpen;
  const [term, setTerm] = useState({ policy: "", content: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    const getInitialState = async () => {
      const { result } = await getTerm(type as string);
      setTerm({
        policy: result.policy,
        content: result.content,
      });
    };
    getInitialState();
  }, []);

  const onClose = useCallback(() => {
    dispatch(openModal({ open: !open }));
  }, []);

  const onAgreeClick = useCallback(async () => {
    await dispatch(updateJoinForm({ [type as string]: true }));
    await onClose();
  }, []);

  if (isMobile) {
    return (
      <Drawer
        visible={open}
        onClose={onClose}
        closable={false}
        placement="bottom"
      >
        <h2>{term.policy}</h2>
        <p>{term.content}</p>
        <div className="term-drawer-button-wrapper">
          <div className="drawer-button">
            <Button type="primary" onClick={onAgreeClick}>
              확인
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Modal
      visible={open}
      footer={null}
      bodyStyle={{
        height: 720,
        width: 800,
        borderRadius: 12,
        padding: "48px 16px",
      }}
      onCancel={onClose}
    >
      {/*<h1>{term.policy}</h1>*/}
      <div dangerouslySetInnerHTML={{ __html: term.content }} />
    </Modal>
  );
}

export default JoinTerms;
