import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, openModal, updateJoinForm } from "../../../store/join";
import { sendCodeSelector, fetchSendCode } from "../../../store/send-code";
import { AppDispatch } from "../../../store";
import useQuery from "../../../utils/useQuery";
import JoinTerms from "./JoinTerms";
import { Button, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "./JoinStep2.scss";
import "../Terms.scss";
import CertificationModal from "../../common/certification-modal/CertificationModal";
import { updateVisible } from "../../../store/check-code";

function JoinStep2() {
  const { form, modalOpen: modal } = useSelector(joinSelector);
  const { success, code, loading } = useSelector(sendCodeSelector);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  // Todo 주석 제거
  useEffect(() => {
    // !form.email && navigate("/login/join?step=1");
  }, []);

  useEffect(() => {
    success && navigate(`/login/signup?step=${step + 1}`);
  }, [success]);

  useEffect(() => {
    success === false && code === false && dispatch(updateVisible(true));
  }, [code, success]);

  const onNextClick = useCallback(async () => {
    if (form.serviceTerms && form.personalTerms) {
      await dispatch(
        fetchSendCode({ email: form.email as string, auth_type: 1 })
      );
    }
  }, [form, dispatch]);

  const onModalOpen = useCallback(
    (terms: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      dispatch(openModal({ open: !modal.open, type: terms }));
    },
    [modal.open, dispatch]
  );

  const onAllTermsClick = useCallback(() => {
    if (!(form.serviceTerms && form.personalTerms)) {
      dispatch(
        updateJoinForm({
          serviceTerms: true,
          personalTerms: true,
        })
      );
    } else {
      dispatch(
        updateJoinForm({
          serviceTerms: false,
          personalTerms: false,
        })
      );
    }
  }, [form.serviceTerms, form.personalTerms, dispatch]);

  const onTermsClick = useCallback(
    (value: string) => () => {
      console.log("CCC");
      dispatch(
        updateJoinForm({
          [value]: !form[value as "serviceTerms" | "personalTerms"],
        })
      );
    },
    [form.serviceTerms, form.personalTerms, dispatch]
  );

  return (
    <div className="join-terms-container">
      <ul className="join-terms">
        <li className={`terms 1 ${form.serviceTerms ? "active" : ""}`}>
          <Checkbox
            checked={form.serviceTerms}
            onChange={onTermsClick("serviceTerms")}
          />
          <span onClick={onTermsClick("serviceTerms")} className="term-title">
            서비스 이용약관
          </span>
          <button onClick={onModalOpen("serviceTerms")}>전문 보기</button>
        </li>

        <li className={`terms 2 ${form.personalTerms ? "active" : ""}`}>
          <Checkbox
            checked={form.personalTerms}
            onChange={onTermsClick("personalTerms")}
          />
          <span onClick={onTermsClick("personalTerms")} className="term-title">
            개인정보처리방침
          </span>
          <button onClick={onModalOpen("personalTerms")}>전문 보기</button>
        </li>
        <li
          className={`all-terms-agree ${
            form.serviceTerms && form.personalTerms ? "active" : ""
          }`}
        >
          <Checkbox
            checked={form.serviceTerms && form.personalTerms}
            onChange={onAllTermsClick}
          />
          <button onClick={onAllTermsClick} className="term-check-all">
            모든 약관 동의
          </button>
        </li>
      </ul>

      {modal.open && <JoinTerms />}

      <div className="join-footer">
        <Button
          type="primary"
          disabled={!(form.serviceTerms && form.personalTerms) || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
      <CertificationModal />
    </div>
  );
}

export default JoinStep2;
