import React from "react";

class CctvMenu extends React.Component {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.902 2.527c-.348-.026-.797-.027-1.458-.027-1.308 0-2.242 0-2.977.056-.726.055-1.188.16-1.561.335A4.167 4.167 0 0 0 2.89 4.906c-.174.373-.279.835-.334 1.561-.056.735-.056 1.67-.056 2.978v1.11c0 1.309 0 2.244.056 2.978.055.726.16 1.188.334 1.561a4.167 4.167 0 0 0 2.016 2.016c.373.174.835.279 1.561.334.735.056 1.67.056 2.977.056.66 0 1.11 0 1.458-.027.339-.025.514-.072.636-.129.355-.166.64-.451.806-.806.057-.122.103-.297.129-.636.026-.348.027-.797.027-1.457v-8.89c0-.66 0-1.109-.027-1.456-.026-.34-.072-.514-.13-.637a1.667 1.667 0 0 0-.805-.806c-.122-.057-.297-.103-.636-.129Zm.125-1.662c.434.033.835.103 1.215.28.71.332 1.281.903 1.612 1.613.178.38.248.78.28 1.215.033.418.033.93.033 1.55v1.253l3.704-2.47A.833.833 0 0 1 19.167 5v10a.833.833 0 0 1-1.296.694l-3.704-2.47v1.252c0 .621 0 1.133-.032 1.552-.033.434-.103.834-.28 1.214a3.333 3.333 0 0 1-1.613 1.613c-.38.177-.78.247-1.215.28-.418.032-.93.032-1.55.032H9.41c-1.266 0-2.264 0-3.069-.061-.82-.062-1.508-.191-2.14-.486A5.833 5.833 0 0 1 1.38 15.8c-.295-.632-.424-1.32-.486-2.14-.06-.805-.06-1.803-.06-3.07V9.41c0-1.266 0-2.264.06-3.069.062-.82.191-1.508.486-2.14A5.833 5.833 0 0 1 4.2 1.38c.632-.294 1.32-.423 2.14-.486.805-.06 1.803-.06 3.07-.06h.065c.62 0 1.133 0 1.551.031Zm3.14 10.356 3.333 2.222V6.557L14.167 8.78v2.442Z"
          fill="#989691"
        />
      </svg>
    );
  }
}

export default CctvMenu;
