import React from "react";

class SpaceAndDeviceMenu extends React.Component {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.833 11.667a1.667 1.667 0 1 1-3.333 0h3.333Z"
          fill="#989691"
        />
        <path
          d="M15.981 1.47c-.672-.343-1.41-.493-2.296-.566-.868-.07-1.946-.07-3.315-.07h-.712c-.831 0-1.552 0-2.178.014-1.447.034-2.53.146-3.462.621a5.833 5.833 0 0 0-2.55 2.55c-.342.671-.492 1.41-.564 2.296C.833 7.183.833 8.26.833 9.63v.74c0 1.37 0 2.447.07 3.315.073.885.223 1.625.566 2.297a5.833 5.833 0 0 0 2.55 2.549c1.088.555 2.403.62 4.308.633.41.003.855.003 1.338.003h.705c1.369 0 2.447 0 3.315-.071.885-.073 1.624-.223 2.296-.565a5.833 5.833 0 0 0 2.55-2.55c.12-.235.217-.48.295-.74.34-1.123.34-2.588.34-4.74V9.63c0-1.37 0-2.447-.07-3.315-.073-.886-.223-1.625-.566-2.297a5.833 5.833 0 0 0-2.549-2.549ZM10.333 2.5c1.414 0 2.424 0 3.216.065.782.064 1.278.186 1.676.39a4.166 4.166 0 0 1 1.82 1.82c.203.398.325.893.39 1.676.064.791.065 1.802.065 3.216v.666c0 1.871-.006 3.014-.136 3.834H5v1.666h2.5v1.652c-1.412-.036-2.15-.146-2.725-.44a4.166 4.166 0 0 1-1.821-1.82c-.203-.398-.325-.893-.389-1.676-.065-.791-.065-1.802-.065-3.216v-.666c0-1.414 0-2.425.065-3.216.064-.783.186-1.278.389-1.676a4.167 4.167 0 0 1 1.82-1.82c.567-.29 1.308-.407 2.745-.44.253-.007.524-.01.814-.012v2.245L5.14 9.538a.833.833 0 0 0 .693 1.295H12.5a.833.833 0 0 0 .693-1.295L10 4.748V2.5h.333ZM9.166 15.833h7.5c-.38.507-.872.923-1.441 1.213-.398.203-.894.325-1.676.389-.792.064-1.802.065-3.216.065H9.166v-1.667ZM7.39 9.167l1.776-2.665 1.777 2.665H7.39Z"
          fill="#989691"
        />
      </svg>
    );
  }
}

export default SpaceAndDeviceMenu;
