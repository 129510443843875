import { Button, Input } from "antd";
import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { fetchUpdateDeviceName } from "../../../store/update-device-name";

function RemoteDeviceContent({
  remoteDevice,
}: {
  remoteDevice: {
    id: number;
    deviceName: string;
    brandName: string;
    deviceType: string;
  };
}) {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(remoteDevice.deviceName);
  const dispatch = useDispatch<AppDispatch>();

  const handleDeviceName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onClickNameDisabled = () => {
    if (name !== remoteDevice.deviceName) {
      dispatch(fetchUpdateDeviceName({ id: remoteDevice.id, name }));
    }
    setEdit((prevState) => {
      return !prevState;
    });
  };
  const onClick = () => {
    setEdit((prevState) => {
      return !prevState;
    });
  };
  return (
    <div className="remote-content" key={remoteDevice.id}>
      <div className="remote-item">
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/device-icons/${remoteDevice.deviceType}.png`}
          alt="remote-device"
        />
        <div className="remote-inform">
          {edit ? (
            <Input
              value={name}
              onChange={handleDeviceName}
              placeholder={remoteDevice.deviceName}
              onPressEnter={onClickNameDisabled}
              onBlur={onClickNameDisabled}
              autoFocus
              maxLength={20}
            />
          ) : (
            <h3>{remoteDevice.deviceName}</h3>
          )}
          <p>{remoteDevice.brandName}</p>
        </div>
      </div>
      <Button onClick={onClick}>
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/edit_primary.png`}
          alt="button"
        />
      </Button>
    </div>
  );
}

export default RemoteDeviceContent;
