import React, { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePaymentMethodSelector,
  updateUpdatePaymentMethodModalVisible,
} from "../../../../store/setting/payment/edit";
import { AppDispatch } from "../../../../store";
import {
  getPaymentMethodOne,
  postUpdatePriority,
} from "../../../../api/payment/payment";

function AddPaymentModal({ completedCallback }: { completedCallback: any }) {
  const { visible, updateId } = useSelector(updatePaymentMethodSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [cardInfo, setCardInfo] = useState("");
  const [priority, setPriority] = useState("main");

  useEffect(() => {
    if (visible) getPaymentMethodDetail();
  }, [visible, updateId]);

  const getPaymentMethodDetail = async () => {
    const { success, result } = await getPaymentMethodOne(updateId);
    if (!success) dispatch(updateUpdatePaymentMethodModalVisible(false));

    const { cardCompany, cardNumber, priority } = result;
    setCardInfo(`${cardCompany} ${cardNumber}`);
  };

  const onCancel = () => {
    dispatch(updateUpdatePaymentMethodModalVisible(false));
  };

  const onChangePriority = (e: any) => {
    setPriority(e.target.value);
  };

  const onChangePayment = async () => {
    let body;
    switch (priority) {
      case "main":
        body = { id: updateId, isMain: "y" };
        break;
      case "no":
        body = { id: updateId, isUse: "n" };
        break;
      case "sub":
      default:
        body = { id: updateId, isUse: "y" };
        break;
    }

    await postUpdatePriority(body);
    dispatch(updateUpdatePaymentMethodModalVisible(false));
    completedCallback();
  };

  return (
    <Modal
      className="add-payment-completed-method"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="add-payment-complete-cancel"
          className="add-payment-complete-cancel"
          onClick={onCancel}
        >
          취소
        </Button>,
        <Button
          key="add-payment-complete-ok"
          className="add-payment-complete-ok"
          onClick={onChangePayment}
        >
          확인
        </Button>,
      ]}
    >
      <div className="add-payment-method-completed-contents">
        <div className="content-title">
          <p>결제 수단 추가 완료</p>
        </div>
        <div className="content-desc">
          <p>
            새로운 결제 수단이 추가되었어요.
            <br />
            결제 수단을 어떤 순서로 사용할지 고를 수 있어요.
          </p>
        </div>
        <div className="content-items">
          <div className="content-items-desc">
            <p>결제 수단</p>
          </div>
          <div className="content-items-type">
            <p className="content-items-type-title">카드 종류</p>
            <p>신용카드</p>
          </div>
          <div className="content-items-card">
            <p className="content-items-type-title">카드 정보</p>
            <p style={{ width: "50%" }}>{cardInfo}</p>
          </div>
        </div>
        <div className="payment-method-list">
          <p className="payment-method-list-title">우선순위</p>
          <select onChange={onChangePriority}>
            <option key="main-card" value="main">
              주 결제 수단으로 사용
            </option>
            <option key="sub-card" value="sub">
              보조 결제 수단으로 사용
            </option>
            <option key="no" value="no">
              사용 안함
            </option>
          </select>
        </div>
        <div className="payment-desc">
          <p>결제 수단을 어떤 순서로 사용할 지 고를 수 있어요</p>
        </div>
      </div>
    </Modal>
  );
}

export default AddPaymentModal;
