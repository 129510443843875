import React from "react";
import { Routes, Route } from "react-router";
import LoginContainer from "./pages/Login";
import AppContainer from "./AppContainer";
import "./styles/index.scss";
import "./App.css";
import ServicePage from "./pages/ServicePage";
import LandingPage from "./pages/LanginPage";
import MultiView from "./pages/MultiView";
import Panel from "./pages/Panel";

function App() {
  return (
    <Routes>
      <Route path="*" element={<AppContainer />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/service" element={<ServicePage />} />
      <Route path="/login/*" element={<LoginContainer />} />
      <Route path="/multi-view" element={<MultiView />} />
      <Route path="/device/panel/*" element={<Panel />} />
    </Routes>
  );
}

export default App;
