import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { sleep } from "../../common/utils";
import { login } from "../../api/login/login";

type LoginState = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  service: string;
};

const initialState: LoginState = {
  loading: false,
  success: undefined,
  service: "basic",
};

export const fetchLogin = createAsyncThunk(
  "login",
  async (body: { id: string; password: string }) => {
    await sleep(100);
    const {
      result: { id, email, username, access_token, service },
    } = await login(body);
    if (access_token) {
      localStorage.setItem("id", id.toString());
      localStorage.setItem("email", email);
      localStorage.setItem("username", username);
      await localStorage.setItem("accessToken", access_token);
      return { result: true, service: service };
    } else {
      return { result: false, service: "basic" };
    }
  }
);

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetLogin: (state: LoginState) => ({
      ...state,
      success: initialState.success,
    }),
    updateUserService: (state, { payload }) => ({
      ...state,
      service: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload.result;
      state.service = payload.service;
    });
    builder.addCase(fetchLogin.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.success = false;
    });
  },
});

export const loginSelector = (state: RootState) => state.login;
export const { resetLogin, updateUserService } = slice.actions;

export default slice;
