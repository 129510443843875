import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import type { MenuProps } from "antd";
import { AppDispatch } from "../../../store";
import {
  settingSelector,
  updateLogoutModal,
  updateSettingMenu,
} from "../../../store/setting";
import SettingMenuIcon from "../../../common/svgs/setting/SettingMenuIcon";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function SettingMenus() {
  const [current, setCurrent] = useState("");
  const { logoutModal, settingMenu } = useSelector(settingSelector);
  const dispatch = useDispatch<AppDispatch>();
  const onSelectMenu = (e: any) => {
    const key = e.keyPath[0];
    if (key === "logout") {
      dispatch(updateLogoutModal(true));
    } else {
      dispatch(updateSettingMenu(key));
    }
    setCurrent(key);
  };
  useEffect(() => {
    if (!logoutModal) {
      setCurrent(settingMenu);
    }
  }, [logoutModal]);

  const items: MenuItem[] = useMemo(() => {
    return [
      getItem(
        <>
          <SettingMenuIcon MenuType={"Account"} />
          <p>계정 관리</p>
        </>,
        "account"
      ),
      getItem(
        <>
          <SettingMenuIcon MenuType={"peristalsis"} />
          <p>서비스 관리</p>
        </>,
        "peristalsis"
      ),
      // getItem(
      //   <>
      //     <SettingMenuIcon MenuType={"payment"} />
      //     <p>결제 관리</p>
      //   </>,
      //   "payment"
      // ),
      getItem(
        <>
          <SettingMenuIcon MenuType={"service"} />
          <p>서비스 정보</p>
        </>,
        "service"
      ),
      getItem(
        <>
          <SettingMenuIcon MenuType={"logout"} />
          <p>로그아웃</p>
        </>,
        "logout"
      ),
    ];
  }, []);

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["account"]}
      style={{ height: "auto" }}
      items={items}
      selectedKeys={[current]}
      onSelect={onSelectMenu}
    />
  );
}

export default SettingMenus;
