import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateJoinForm } from "../../../store/join";
import { Input } from "antd";
import "./AccountForm.scss";

function AccountForm() {
  const dispatch = useDispatch();

  const onAccountNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateJoinForm({ username: e.target.value }));
    },
    []
  );

  return (
    <div className="account-form">
      <div className="account-input-form">
        <label>이름</label>
        <Input
          placeholder="홍길동, Hong gil dong, 길동이💚"
          onChange={onAccountNameChange}
          maxLength={12}
        />
      </div>
    </div>
  );
}

export default AccountForm;
