import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type updatePaymentMethodState = {
  visible: boolean;
  updateId: number;
};

const initialState: updatePaymentMethodState = {
  visible: false,
  updateId: 0,
};

const slice = createSlice({
  name: "setting/updatePaymentMethod",
  initialState,
  reducers: {
    resetUpdatePaymentMethodModalVisible: (
      state: updatePaymentMethodState
    ) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateUpdatePaymentMethodModalVisible: (
      state: updatePaymentMethodState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
    setUpdatePaymentMethodId: (
      state: updatePaymentMethodState,
      { payload }
    ) => ({
      ...state,
      updateId: payload,
    }),
  },
});

export const updatePaymentMethodSelector = (state: RootState) =>
  state.setting.payment.edit;

export const {
  resetUpdatePaymentMethodModalVisible,
  updateUpdatePaymentMethodModalVisible,
  setUpdatePaymentMethodId,
} = slice.actions;

export default slice;
