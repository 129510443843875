import { combineReducers } from "@reduxjs/toolkit";
import dashboardMain from "./dashboard/main";
import dashboardRegister from "./dashboard/register";
import dashboardRename from "./dashboard/rename";
import dashboardDelete from "./dashboard/delete";
import dashboardWidget from "./dashboard/widget/main";
import dashboardWidgetDelete from "./dashboard/widget/delete";
import dashboardWidgetRegister from "./dashboard/widget/register";
import categoryDeviceList from "./dashboard/widget/category-device-list";
import widgetDeviceList from "./dashboard/widget/deviceList";
import widgetList from "./dashboard/widget/list";
import dashboardWidgetControl from "./dashboard/widget/control";
import widgetWarning from "./dashboard/widget/warning";
import login from "./login";
import autoLogin from "./autoLogin";
import join from "./join";
import verifyEmail from "./verify-email";
import sendCode from "./send-code";
import checkCode from "./check-code";
import resetPassword from "./reset-password";
import spaceList from "./space/list";
import spaceDetail from "./space/detail";
import spaceRemove from "./space/remove";
import noSpaceList from "./space/no-space-list";
import addDeviceInSpace from "./space/add-device-in-space";
import addSpace from "./space/register";
import updateSpace from "./space/update";
import deviceListInSpace from "./space/device-list";
import spaceDeviceSelectedList from "./space/device-setting/list";
import removeSpaceDeviceList from "./space/device-setting/remove";
import moveSpaceDeviceList from "./space/device-setting/move";
import removeNoSpaceDeviceList from "./space/remove-no-space-device-list";
import spacePanel from "./space/panel";
import dashboardList from "./dashboard-manage/list";
import addDashboard from "./dashboard-manage/register";
import widget from "./dashboard-manage/widget";
import setting from "./setting";
import accountUpdate from "./setting/account/update";
import accountUpdatePassword from "./setting/account/updatePassword";
import syncPlatforms from "./setting/account/syncPlatforms";
import airposLogin from "./setting/airpos/login";
import airposMain from "./setting/airpos";
import airposList from "./setting/airpos/list";
import airposCameraList from "./setting/airpos/camera";
import airposDelete from "./setting/airpos/delete";
import serviceInfo from "./setting/service-info";
import recipeRegister from "./recipe/register";
import recipeDetail from "./recipe/detail";
import recipeConditionValue from "./recipe/condition";
import recipeConditionList from "./recipe/condition-list";
import recipeActionValue from "./recipe/action";
import recipeActionList from "./recipe/action-list";
import allDeviceListCondition from "./recipe/all-device-list-condition";
import allDeviceListAction from "./recipe/all-device-list-action";
import noSpaceDeviceListAction from "./recipe/no-space-list-action";
import noSpaceDeviceListCondition from "./recipe/no-space-list-condition";
import recipeUpdate from "./recipe/update";
import recipeList from "./recipe/list";
import recipeSetting from "./recipe/setting";
import recipeRemove from "./recipe/remove";
import recipeExecute from "./recipe/execute";
import recipeHistory from "./recipe/history";
import accountPasswordUpdate from "./setting/account/updatePassword";
import layout from "./layout";
import posList from "./sell-manage/list";
import posReceipt from "./sell-manage/history/receipt";
import posToday from "./sell-manage/history/today";
import posMonthConclusion from "./sell-manage/history/conclusion/monthly";
import posWeeklyConclusion from "./sell-manage/history/conclusion/weekly";
import posCommonConclusion from "./sell-manage/history/conclusion/common";
import posSearchConclusion from "./sell-manage/history/conclusion/search";
import posSetting from "./sell-manage/setting";
import toastcamLogin from "./setting/toascam/login";
import toastcamMain from "./setting/toascam";
import toastcamList from "./setting/toascam/list";
import toastcamCameraList from "./setting/toascam/camera";
import toastcamDelete from "./setting/toascam/delete";
import panelDeviceDetail from "./panel/detail";
import panelDeviceControl from "./panel/control";
import panelRemoteList from "./panel/remote-list";
import categoryList from "./panel/category-list";
import registerRemoteDevice from "./panel/add-remote-device";
import cctv from "./cctv";
import cctvPlay from "./cctv/play";
import cctvEvent from "./cctv/event";
import cctvDetail from "./cctv/detail";
import motionDetectZones from "./cctv/motiondetects";
import history from "./history/main";
import historyDetail from "./history/detail";
import cctvReceiptVideo from "./cctv/receiptVideo";
import updateDeviceName from "./update-device-name";
import payment from "./setting/payment";
import addPayment from "./setting/payment/add";
import addCompletePayment from "./setting/payment/addComplete";
import editPaymentMethod from "./setting/payment/edit";
import userMembership from "./setting/user-membership";
import userMembershipChangeConfirm from "./setting/payment/confirm";
import paymentHistoryModal from "./setting/payment/history";

export default combineReducers({
  dashboard: combineReducers({
    list: dashboardMain.reducer,
    register: dashboardRegister.reducer,
    rename: dashboardRename.reducer,
    delete: dashboardDelete.reducer,
    widget: combineReducers({
      register: dashboardWidgetRegister.reducer,
      categoryDeviceList: categoryDeviceList.reducer,
      deviceList: widgetDeviceList.reducer,
      list: widgetList.reducer,
      main: dashboardWidget.reducer,
      delete: dashboardWidgetDelete.reducer,
      control: dashboardWidgetControl.reducer,
      warning: widgetWarning.reducer,
    }),
  }),
  login: login.reducer,
  autoLogin: autoLogin.reducer,
  join: join.reducer,
  verifyEmail: verifyEmail.reducer,
  sendCode: sendCode.reducer,
  checkCode: checkCode.reducer,
  resetPassword: resetPassword.reducer,
  space: combineReducers({
    list: spaceList.reducer,
    noList: noSpaceList.reducer,
    detail: spaceDetail.reducer,
    addDevice: addDeviceInSpace.reducer,
    register: addSpace.reducer,
    update: updateSpace.reducer,
    remove: spaceRemove.reducer,
    deviceList: deviceListInSpace.reducer,
    removeNoSpaceDeviceList: removeNoSpaceDeviceList.reducer,
    deviceSetting: combineReducers({
      list: spaceDeviceSelectedList.reducer,
      remove: removeSpaceDeviceList.reducer,
      move: moveSpaceDeviceList.reducer,
    }),
    panel: spacePanel.reducer,
  }),
  dashboardManage: combineReducers({
    list: dashboardList.reducer,
    register: addDashboard.reducer,
    widget: widget.reducer,
  }),
  setting: combineReducers({
    main: setting.reducer,
    account: combineReducers({
      update: accountUpdate.reducer,
      updatePassword: accountUpdatePassword.reducer,
      syncPlatformList: syncPlatforms.reducer,
    }),
    airpos: combineReducers({
      login: airposLogin.reducer,
      main: airposMain.reducer,
      list: airposList.reducer,
      cameraList: airposCameraList.reducer,
      delete: airposDelete.reducer,
    }),
    serviceInfo: serviceInfo.reducer,
    toastcam: combineReducers({
      login: toastcamLogin.reducer,
      main: toastcamMain.reducer,
      list: toastcamList.reducer,
      cameraList: toastcamCameraList.reducer,
      delete: toastcamDelete.reducer,
    }),
    payment: combineReducers({
      payment: payment.reducer,
      add: addPayment.reducer,
      addCompleted: addCompletePayment.reducer,
      historyModal: paymentHistoryModal.reducer,
      edit: editPaymentMethod.reducer,
    }),
    userMembership: combineReducers({
      update: userMembership.reducer,
      confirm: userMembershipChangeConfirm.reducer,
      history: userMembership.reducer,
    }),
  }),
  recipe: combineReducers({
    register: recipeRegister.reducer,
    condition: recipeConditionValue.reducer,
    conditionList: recipeConditionList.reducer,
    action: recipeActionValue.reducer,
    actionList: recipeActionList.reducer,
    allDeviceListCondition: allDeviceListCondition.reducer,
    allDeviceListAction: allDeviceListAction.reducer,
    noSpaceDeviceListAction: noSpaceDeviceListAction.reducer,
    noSpaceDeviceListCondition: noSpaceDeviceListCondition.reducer,
    update: recipeUpdate.reducer,
    list: recipeList.reducer,
    setting: recipeSetting.reducer,
    remove: recipeRemove.reducer,
    history: recipeHistory.reducer,
    detail: recipeDetail.reducer,
    execute: recipeExecute.reducer,
  }),
  updateAccountPassword: accountPasswordUpdate.reducer,
  layout: layout.reducer,
  pos: combineReducers({
    list: posList.reducer,
    posToday: posToday.reducer,
    posReceipt: posReceipt.reducer,
    monthConclusion: posMonthConclusion.reducer,
    weeklyConclusion: posWeeklyConclusion.reducer,
    commonConclusion: posCommonConclusion.reducer,
    searchConclusion: posSearchConclusion.reducer,
    posSetting: posSetting.reducer,
  }),
  panel: combineReducers({
    detail: panelDeviceDetail.reducer,
    control: panelDeviceControl.reducer,
    remoteList: panelRemoteList.reducer,
    categoryList: categoryList.reducer,
    registerRemote: registerRemoteDevice.reducer,
  }),
  cctv: combineReducers({
    list: cctv.reducer,
    play: cctvPlay.reducer,
    detail: cctvDetail.reducer,
    event: cctvEvent.reducer,
    motionDetectZones: motionDetectZones.reducer,
    cctvReceiptVideo: cctvReceiptVideo.reducer,
  }),
  history: combineReducers({
    main: history.reducer,
    detail: historyDetail.reducer,
  }),
  updateDeviceName: updateDeviceName.reducer,
});
