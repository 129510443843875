import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { getUseMembershipHistory } from "../../../api/user/user";
import { fetchGetSyncPlatformList } from "../account/syncPlatforms";

type memberShipChangeSchedule = {
  remainDashboardId: number;
  remainSpaceId: number;
  schedule: string;
};

type memberShipPaymentSchedule = {
  amount: number;
  schedule: string;
};

type userMembershipHistory = {
  memberShipChangeSchedule: memberShipChangeSchedule[];
  memberShipPaymentSchedule: memberShipPaymentSchedule[];
};

type ChangeUserMembershipState = {
  loading: boolean;
  error?: string | Error;
  visible: boolean;
  rollbackMembershipModalVisible: boolean;
  userMembership: string;
  userMembershipHistory: userMembershipHistory;
};

const initialState: ChangeUserMembershipState = {
  loading: false,
  error: undefined,
  visible: false,
  rollbackMembershipModalVisible: false,
  userMembership: "basic",
  userMembershipHistory: {
    memberShipChangeSchedule: [],
    memberShipPaymentSchedule: [],
  },
};

export const fetchGetUserMembershipHistory = createAsyncThunk(
  "setting/changeUserMembership/history",
  async () => {
    await sleep(100);
    const { result } = await getUseMembershipHistory();
    return result;
  }
);

const slice = createSlice({
  name: "setting/changeUserMembership/main",
  initialState,
  reducers: {
    resetChangeuserMembershipVisible: (state: ChangeUserMembershipState) => ({
      ...state,
      visible: initialState.visible,
      userMembership: initialState.userMembership,
    }),
    updateChangeUsersMembershipVisible: (
      state: ChangeUserMembershipState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
    updateRollbackMembershipVisible: (
      state: ChangeUserMembershipState,
      { payload }
    ) => ({
      ...state,
      rollbackMembershipModalVisible: payload,
    }),
    updateNowUserMembership: (
      state: ChangeUserMembershipState,
      { payload }
    ) => ({
      ...state,
      userMembership: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetUserMembershipHistory.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetUserMembershipHistory.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.userMembershipHistory = payload;
      }
    );
    builder.addCase(
      fetchGetUserMembershipHistory.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const settingChangeuserMembershipSelector = (state: RootState) =>
  state.setting.userMembership.update;

export const settingMembershipHistorySelector = (state: RootState) =>
  state.setting.userMembership.history;

export const {
  resetChangeuserMembershipVisible,
  updateChangeUsersMembershipVisible,
  updateRollbackMembershipVisible,
  updateNowUserMembership,
} = slice.actions;

export default slice;
