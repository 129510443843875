import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Typography } from "antd";
const { Paragraph } = Typography;
interface popupProps {
  visible: boolean;
  onClickOk: any;
  onClickCancel: any;
}

export function ResignAccountPopup(props: popupProps) {
  const [password, setPassword] = useState("");
  useEffect(() => {}, []);

  return (
    <Modal
      className="resign-modal"
      visible={props.visible}
      closable={false}
      footer={[
        <Button
          key={"cancelBtn"}
          className="cancel"
          onClick={props.onClickCancel}
        >
          취소
        </Button>,
        <Button key={"okBtn"} danger onClick={props.onClickOk} disabled={true}>
          탈퇴
        </Button>,
      ]}
    >
      <h1>회원탈퇴</h1>
      <Typography>
        <Paragraph className="resign-announce">
          정말로 Hejhome Square 회원을 탈퇴 하시겠습니까?
          <br />
          탈퇴하기 전 아래와 같은 사항을 반드시 확인해주세요.
        </Paragraph>
        <Paragraph>
          <div className="resign-policy">
            <div className="resign-policy-icon">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/resign/device.png`}
                alt="resign-policy-icon"
              />
            </div>
            <div className="resign-policy-desc">
              <p className="resign-policy-desc-main">
                계정에 연동된 모든 기기가 삭제(페어링 해제)돼요.
              </p>
              <p className="resign-policy-desc-sub">
                삭제된 기기는 다시 연결해야 사용할 수 있어요.
              </p>
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="resign-policy">
            <div className="resign-policy-icon">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/resign/refund.png`}
                alt="resign-policy-icon"
              />
            </div>
            <div className="resign-policy-desc">
              <p className="resign-policy-desc-main">
                모든 연동 서빗가 연동 해제돼요.
              </p>
              <p className="resign-policy-desc-sub">
                사용 중인 유료 서비스가 있다면 별도의 환불 없이 중단돼요.
              </p>
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="resign-policy">
            <div className="resign-policy-icon">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/resign/account.png`}
                alt="resign-policy-icon"
              />
            </div>
            <div className="resign-policy-desc">
              <p className="resign-policy-desc-main">
                모든 장소 및 대시보드가 삭제돼요.
              </p>
              <p className="resign-policy-desc-sub">
                삭제된 장소 및 대시보드는 복구할 수 없어요.
              </p>
            </div>
          </div>
        </Paragraph>
        {/*<Paragraph*/}
        {/*  style={{*/}
        {/*    fontSize: "14px",*/}
        {/*    lineHeight: "20px",*/}
        {/*    fontWeight: "700",*/}
        {/*    color: "gray",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  회원탈퇴를 진행하려면 계정 비밀번호를 입력해주세요.*/}
        {/*</Paragraph>*/}
      </Typography>
      <div className="input-password-box">
        {/*<Form.Item label="비밀번호" className="resign-password-input-item">*/}
        {/*  <Input.Password*/}
        {/*    bordered={false}*/}
        {/*    className="resign-password-input"*/}
        {/*    defaultValue={password}*/}
        {/*    placeholder={"탈퇴할 계정의 비밀번호를 입력해주세요"}*/}
        {/*    onChange={(e) => {*/}
        {/*      setPassword(e.target.value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        <div className="input-password-box-desc">
          회원 탈퇴를 계속하려면 계정 비밀번호를 입력해주세요.
        </div>
        <div className="input-password-box-content">
          <div className="input-password-box-content-title">비밀번호</div>
          <div className="input-password-box-content-item">
            <input
              placeholder="탈퇴할 계정의 비밀번호를 입력해주세요."
              type="password"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
