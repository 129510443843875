import { authGet, authPost } from "../../common/api";

type TermResponse = {
  result: {
    policy: string;
    content: string;
  };
};

type ResultBooleanResponse = {
  result: boolean;
  message: string;
};

type ResultSuccessCodeBooleanResponse = {
  result: {
    success: boolean;
    code: boolean;
  };
  message: string;
};

export type SendCodeRequest = {
  email: string;
  auth_type: number;
};

export type CheckCodeRequest = {
  email: string;
  code: number;
  auth_type: number;
};

export type JoinRequest = {
  email: string;
  password: string;
  name: string;
};

export type ResetRequest = {
  email: string;
  password: string;
};

export type ResponseAuthUserInfo = {
  result: boolean;
  data: {
    name: string;
    phone: string;
  };
};

export const getTerm = async (term: string) => {
  try {
    const { data } = await authGet(`/v1.0/auth/join/terms/${term}`);
    return data as TermResponse;
  } catch (e) {
    throw e;
  }
};

export const emailVerify = async (email: string) => {
  try {
    const { data } = await authPost(`/v1.0/auth/verify?email`, { email });
    return data as ResultBooleanResponse;
  } catch (e) {
    throw e;
  }
};

export const sendEmailCode = async ({ email, auth_type }: SendCodeRequest) => {
  try {
    const { data } = await authPost(`/v1.0/auth/code`, { email, auth_type });
    return data as ResultSuccessCodeBooleanResponse;
  } catch (e) {
    throw e;
  }
};

export const checkCode = async ({
  email,
  code,
  auth_type,
}: CheckCodeRequest) => {
  try {
    const { data } = await authPost("/v1.0/auth/code/check", {
      email,
      code,
      auth_type,
    });
    return data as ResultBooleanResponse;
  } catch (e) {
    throw e;
  }
};

export const join = async ({ email, password, name }: JoinRequest) => {
  try {
    const { data } = await authPost("/v1.0/auth/join", {
      email,
      password,
      name,
    });
    return data as ResultBooleanResponse;
  } catch (e) {
    throw e;
  }
};

export const resetPassword = async ({ email, password }: ResetRequest) => {
  try {
    const { data } = await authPost("/v1.0/auth/password-reset", {
      email,
      password,
    });
    return data as ResultBooleanResponse;
  } catch (e) {
    throw e;
  }
};

export const getUserAuthInfo = async (impUid: string) => {
  try {
    const { data } = await authPost("/v1.0/auth/certifications", {
      imp_uid: impUid,
    });
    return data as ResponseAuthUserInfo;
  } catch (e) {
    throw e;
  }
};
