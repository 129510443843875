import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailVerifySelector, resetValid } from "../../../store/verify-email";
import { emailRegex } from "../../../utils/regax";
import { updateJoinForm } from "../../../store/join";
import { updateResetPasswordForm } from "../../../store/reset-password";
import { Input } from "antd";
import "./EmailForm.scss";

function EmailForm() {
  const { valid } = useSelector(emailVerifySelector);

  const dispatch = useDispatch();

  const [invalid, setInvalid] = useState(false);
  const [usingEmail, setUsingEmail] = useState(false);
  const [isExistEmail, setIsExistEmail] = useState(true);

  const { pathname } = useLocation();
  const route = pathname.split("/")[2];

  useEffect(() => {
    const getInitialState = async () => {
      if (route === "signup" && valid !== undefined && !valid)
        await setUsingEmail(true);

      if (route === "reset-password" && valid !== undefined && valid)
        await setIsExistEmail(false);
    };
    getInitialState();
  }, [valid, route]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = emailRegex(value);

    dispatch(resetValid());
    setUsingEmail(false);
    setIsExistEmail(true);

    if (isValid) {
      setInvalid(false);
      if (route === "signup") {
        dispatch(updateJoinForm({ email: value }));
      } else {
        dispatch(updateResetPasswordForm({ email: value }));
      }
    } else {
      setInvalid(true);
      if (route === "signup") {
        dispatch(updateJoinForm({ email: undefined }));
      } else {
        dispatch(updateResetPasswordForm({ email: undefined }));
      }
    }
  };

  return (
    <div className="email-form">
      <div
        className={`email-input-form ${
          invalid || usingEmail || !isExistEmail ? "invalid" : ""
        }`}
      >
        <label>이메일</label>
        <Input placeholder="hej-is-hey@email.com" onChange={onEmailChange} />
      </div>
      <div className="email-warning">
        {invalid && "올바르게 이메일을 입력해주세요."}
        {usingEmail && "이미 사용중인 이메일입니다."}
        {!isExistEmail &&
          "사용중인 계정을 찾을 수 없습니다. 다시 확인해주세요."}
      </div>
    </div>
  );
}

export default EmailForm;
