import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type paymentHistoryModalState = {
  visible: boolean;
};

const initialState: paymentHistoryModalState = {
  visible: false,
};

const slice = createSlice({
  name: "setting/addPayment/history",
  initialState,
  reducers: {
    resetHistoryModalVisible: (state: paymentHistoryModalState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updatePaymentHistoryModalVisible: (
      state: paymentHistoryModalState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const settingPaymentHistorySelector = (state: RootState) =>
  state.setting.payment.historyModal;

export const { resetHistoryModalVisible, updatePaymentHistoryModalVisible } =
  slice.actions;

export default slice;
