import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../../common/utils";
import { RootState } from "../../../../index";
import { getPosSummaryDaily } from "../../../../../api/pos/pos";

export type searchConclusions = {
  totalAmount: string;
  rateOfSalesDaily: {
    type: string;
    amount: string;
    rate: string;
    salesCount: string;
  };
  records: {
    id: string;
    posId: string;
    saleTime: string;
    salesType: string;
    salesAmount: string;
  }[];
};

type SearchConclusion = {
  loading: boolean;
  error?: string | Error;
  search: searchConclusions;
};

const initialState: SearchConclusion = {
  loading: false,
  error: undefined,
  search: {
    totalAmount: "",
    rateOfSalesDaily: {
      type: "",
      amount: "",
      rate: "",
      salesCount: "",
    },
    records: [],
  },
};

export const fetchGetSearchConclusion = createAsyncThunk(
  "airPos/search/receipt/conclusion",
  async ({ date, deviceId }: { date: string; deviceId?: number }) => {
    await sleep(100);
    const { result } = await getPosSummaryDaily({
      date: date,
      deviceId,
    });
    // return result;

    result.records = result.records.filter((item1, idx1) => {
      return (
        result.records.findIndex((item2, idx2) => {
          return (
            item1.saleTime == item2.saleTime &&
            item1.salesType == item2.salesType
          );
        }) == idx1
      );
    });

    return result;
  }
);

const slice = createSlice({
  name: "airPos/search/conclusion",
  initialState,
  reducers: {
    resetSearchConclusion: (state) => ({
      ...state,
      searchConclusion: initialState.search,
    }),
    updateSortSearchConclusion: (state, { payload }) => ({
      ...state,
      search: {
        ...state.search,
        records: payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetSearchConclusion.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetSearchConclusion.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.search = payload;
      }
    );
    builder.addCase(fetchGetSearchConclusion.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posSearchConclusionSelector = (state: RootState) =>
  state.pos.searchConclusion;
export const { resetSearchConclusion, updateSortSearchConclusion } =
  slice.actions;

export default slice;
