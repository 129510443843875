import { apiGet, apiPost } from "../../common/api";

type CctvListResponseType = {
  status: number;
  result: {
    id: number;
    device_name: string;
    device_id: string;
    pid: string;
    thumbnailUrl: string;
  }[];
};
export type CctvDetailType = {
  id: number;
  deviceName: string;
  spaceName: string;
  updated_at?: string;
  thumbnailUrl: string;
};
export type CctvDetailResponseType = {
  status: number;
  result: CctvDetailType;
};

type CctvPlayInformationResponseType = {
  status: number;
  message: string;
  data: {
    message: string;
    result: {
      id: number;
      token: string;
      cameraId: string;
      serialNo: string;
      scvHostPort: string;
      hlsStreamUrl: string;
    };
  };
};

type events = {
  id: string;
  durationMs: string;
  startTime: string;
  endTime: string;
  playStartTime: string;
  thumbnailUrl: string;
  eventZones: {
    zoneId: string;
    zoneName: string;
    eventType: string;
    filtered: boolean;
  }[];
}[];

type CctvEventListResponseType = {
  message: string;
  data: {
    code: number;
    message: string;
    result: {
      events: events;
    };
  };
};

type CctvEventVideoResponseType = {
  message: string;
  data: {
    code: number;
    message: string;
    result: {
      cameraId: string;
      cvrHostPort: string;
      hlsStreamUrl: string;
      serialNo: string;
      token: string;
    };
  };
};

type movementZones = {
  zoneIndex: string;
  labelName: string;
  color: string;
  coordinate: string;
  modifyDate: number;
  regDate: number;
}[];

type CctvMotionZonesResponseType = {
  message: string;
  data: {
    message: string;
    result: {
      zones: movementZones;
    };
  };
};

type CctvReceiptVideoResponseType = {
  message: string;
  data: {
    code: number;
    message: string;
    result: {
      cameraId: string;
      cvrHostPort: string;
      hlsStreamUrl: string;
      serialNo: string;
      token: string;
    };
  };
};

export const getCctvList = async (spaceId?: number) => {
  try {
    let query = "";
    if (spaceId) query = `?space=${spaceId}`;

    const { data } = await apiGet(`/v1.0/openapi/cctv${query}`);
    return data as CctvListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCctvPlayInformation = async (cameraId: number) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/cctv/play/${cameraId}`);
    return data as CctvPlayInformationResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCctvEvents = async (
  cameraId: number,
  start: string,
  end: string,
  scale?: string
) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/cctv/event/${cameraId}?start=${start}&end=${end}${
        scale ? `&scale=${scale}` : ``
      }`
    );
    return data as CctvEventListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCctvEventVideo = async (
  cameraId: number,
  token: string,
  timestamp: string
) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/cctv/event/${cameraId}/video?token=${token}&timestamp=${timestamp}&audio=false`
    );
    return data as CctvEventVideoResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCctvMotionZones = async (cameraId: number) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/cctv/motiondetectzones/${cameraId}`
    );
    return data as CctvMotionZonesResponseType;
  } catch (e) {
    throw e;
  }
};

export const postCctvMotionZoneUpdate = async (
  cameraId: number,
  zoneIndex: string,
  labelName: string,
  coordinate: string
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/cctv/motiondetectzone/${cameraId}/update/${zoneIndex}`,
      { labelName, coordinate }
    );
    return data as CctvMotionZonesResponseType;
  } catch (e) {
    throw e;
  }
};

export const putCctvMotionZone = async (
  cameraId: number,
  labelName: string,
  coordinate: string
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/cctv/motiondetectzone/${cameraId}/create`,
      { labelName, coordinate }
    );
    return data as CctvMotionZonesResponseType;
  } catch (e) {
    throw e;
  }
};

export const deleteCctvMotionZone = async (
  cameraId: number,
  zoneIndex: string
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/cctv/motiondetectzone/${cameraId}/delete/${zoneIndex}`,
      {}
    );
    return data as CctvMotionZonesResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCctvReceiptVideo = async (
  cameraId: number,
  timestamp: string
) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/cctv/pos/receipt/${cameraId}/video?timestamp=${timestamp}&audio=false`
    );
    return data as CctvReceiptVideoResponseType;
  } catch (e) {
    throw e;
  }
};
export const getCctvDetail = async (cameraId: number) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/cctv/${cameraId}`);
    return data as CctvDetailResponseType;
  } catch (e) {
    throw e;
  }
};
