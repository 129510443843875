import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type addPaymentCompleteState = {
  visible: boolean;
  insertId: number;
  cardCompany: string;
  cardNumber: string;
};

const initialState: addPaymentCompleteState = {
  visible: false,
  insertId: 0,
  cardCompany: "",
  cardNumber: "",
};

const slice = createSlice({
  name: "setting/addPaymentComplete/main",
  initialState,
  reducers: {
    resetAddPaymentCompleteModalVisible: (state: addPaymentCompleteState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateAddPaymentCompleteModalVisible: (
      state: addPaymentCompleteState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
    updateAddPaymentCompleteCardInfo: (
      state: addPaymentCompleteState,
      { payload }
    ) => ({
      ...state,
      insertId: payload.insertId,
      cardCompany: payload.cardNumber,
      cardNumber: payload.cardCompany,
    }),
  },
});

export const settingAddPaymentCompleteSelector = (state: RootState) =>
  state.setting.payment.addCompleted;

export const {
  resetAddPaymentCompleteModalVisible,
  updateAddPaymentCompleteModalVisible,
  updateAddPaymentCompleteCardInfo,
} = slice.actions;

export default slice;
