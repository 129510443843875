import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Button, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  fetchUpdateAccountUserName,
  settingAccountUpdateSelector,
} from "../../../store/setting/account/update";
import { ChangePasswordPopup } from "./ChangePasswordPopup";
import { ResignAccountPopup } from "./ResignAccountPopup";

function AccountManage() {
  const localStorage = window.localStorage;

  const currentName = useMemo(() => {
    return localStorage.getItem("username") as string;
  }, []);
  const currentEmail = useMemo(() => {
    return localStorage.getItem("email") as string;
  }, []);

  const [name, setName] = useState(currentName);
  const [email] = useState(currentEmail);
  const [editName, setEditName] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [showResignAccountPopup, setShowResignAccountPopup] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { loading: updateLoading, success: updateResult } = useSelector(
    settingAccountUpdateSelector
  );

  useEffect(() => {
    if (updateResult) {
      localStorage.setItem("username", name);

      setTimeout(function () {
        setEditName(false);
      }, 1500);
    }
  }, [updateLoading, updateResult]);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditName(true);
    setName(e.target.value);
  };

  const onClickChangeName = () => {
    dispatch(fetchUpdateAccountUserName(name));
    setEditName(false);
  };

  const onClickCancelChangeName = () => {
    setEditName(false);
    setName(currentName);
  };

  const onClickShowChangePasswordPopup = () => {
    setShowChangePasswordPopup(true);
  };

  const onClickChangePasswordPopupOk = () => {
    setShowChangePasswordPopup(false);
  };

  const onClickChangePasswordPopupCancel = () => {
    setShowChangePasswordPopup(false);
  };

  const onClickShowResignAccountPopup = () => {
    setShowResignAccountPopup(true);
  };

  const onClickResignAccountPopupOk = () => {
    setShowResignAccountPopup(false);
  };

  const onClickResignAccountPopupCancel = () => {
    setShowResignAccountPopup(false);
  };

  const onClickEditName = () => {
    setEditName(true);
  };

  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>계정 관리</h1>
        <p>계정</p>
      </div>
      <div className="account-manage-form">
        <div className="manage-item-list">
          <div className="manage-item-image">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/setting/name.png`}
              alt="setting-name"
            />
            <p className="manage-item-value-name">사용자 이름</p>
          </div>
          <div className="manage-item-buttons">
            <input
              className={
                editName ? "input-user-name-on" : "input-user-name-off"
              }
              placeholder={currentName}
              value={name}
              name="name"
              onChange={onNameChange}
              maxLength={16}
            />
            {editName ? (
              <>
                <div
                  className="input-user-name-button-cancel"
                  onClick={onClickCancelChangeName}
                >
                  취소
                </div>
                <div
                  className="input-user-name-button-save"
                  onClick={onClickChangeName}
                >
                  저장
                </div>
              </>
            ) : (
              <div className="input-user-name-button" onClick={onClickEditName}>
                변경
              </div>
            )}
          </div>
        </div>
        <div className="manage-item-list">
          <div className="manage-item-image">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/setting/email.png`}
              alt="setting-email"
            />
            <p className="manage-item-value-name-email">이메일</p>
          </div>
          <div className="manage-item-email">
            <p>{email}</p>
          </div>
        </div>
        <div className="manage-item-list">
          <div className="manage-item-image">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/setting/password.png`}
              alt="setting-password"
            />
            <div>
              <p className="manage-item-value-name-password">비밀번호 변경</p>
              <p className="manage-item-value-name-sub">
                비밀번호를 변경할수있어요.
              </p>
            </div>
          </div>
          <div className="manage-item-value">
            <div
              className="change-password-button"
              onClick={onClickShowChangePasswordPopup}
            >
              변경
            </div>
          </div>
        </div>
        <div className="manage-item-list">
          <div className="manage-item-image">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/setting/phone.png`}
              alt="setting-phone"
            />
            <div>
              <p className="manage-item-value-name-phone">휴대전화번호 인증</p>
              <p className="manage-item-value-name-sub">
                01012345678 번호로 본인 인증이 완료되었어요.
              </p>
            </div>
          </div>
          <div className="manage-item-value">
            <div className="change-phone-button">변경</div>
          </div>
        </div>
      </div>
      <div className="setting-content">
        <p>기타</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/logout-icon.png`}
            alt="security_icon"
          />
          <div className="setting-info">
            <h1>회원 탈퇴</h1>
            <p>Hejhome Square에서 탈퇴합니다.</p>
          </div>
          <Button type="primary" danger onClick={onClickShowResignAccountPopup}>
            회원 탈퇴
          </Button>
        </div>
      </div>

      {showChangePasswordPopup && (
        <ChangePasswordPopup
          visible={showChangePasswordPopup}
          onClickOk={onClickChangePasswordPopupOk}
          onClickCancel={onClickChangePasswordPopupCancel}
        />
      )}

      {showResignAccountPopup && (
        <ResignAccountPopup
          visible={showResignAccountPopup}
          onClickOk={onClickResignAccountPopupOk}
          onClickCancel={onClickResignAccountPopupCancel}
        />
      )}
    </div>
  );
}
export default AccountManage;
