import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import type { MenuProps } from "antd";

import { AppDispatch } from "../../store";
import { spaceListSelector } from "../../store/space/list";
import {
  addDashboardTabList,
  dashboardSelector,
  removeDashboardTab,
  renameDashboardTab,
  updateCurrentDashboard,
  updateDashboardTabList,
} from "../../store/dashboard/main";
import {
  dashboardListSelector,
  fetchGetDashboardList,
} from "../../store/dashboard-manage/list";
import { fetchGetSpaceList } from "../../store/space/list";
import {
  removeSpaceTab,
  renameSpaceTab,
  spaceDetailSelector,
  updateCurrentSpaceId,
  updateSpaceTabList,
} from "../../store/space/detail";
import { updateOpenSettingModal, settingSelector } from "../../store/setting";
import HoverSetting from "./HoverSetting";
import "./navigation.scss";
import { layoutSelector, resetLayout } from "../../store/layout";
import {
  dashboardRegisterSelector,
  resetDashboardRegister,
} from "../../store/dashboard/register";
import {
  dashboardRenameSelector,
  resetDashboardRename,
} from "../../store/dashboard/rename";
import {
  dashboardDeleteSelector,
  resetDashboardDelete,
} from "../../store/dashboard/delete";
import { createSpaceSelector } from "../../store/space/register";
import {
  updateSpaceSelector,
  resetUpdateSpace,
} from "../../store/space/update";
import {
  resetSpaceRemove,
  removeSpaceSelector,
} from "../../store/space/remove";
import { loginSelector, updateUserService } from "../../store/login";
import DashboardMenu from "../../assets/img/menu/dashboard";
import CctvMenu from "../../assets/img/menu/cctv";
import Sales from "../../assets/img/menu/sales";
import SpaceAndDeviceMenu from "../../assets/img/menu/spaceAndDevice";
import HistoryMenu from "../../assets/img/menu/history";
import RoutineMenu from "../../assets/img/menu/routine";
import SettingMenu from "../../assets/img/menu/setting";
import { getUpdateDetail } from "../../api/user/user";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  className?: string,
  disabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    className,
    disabled,
  } as MenuItem;
}

function NavigationMenus() {
  const [currentMenu, setCurrentMenu] = useState("");
  const [current, setCurrent] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { openSettingModal } = useSelector(settingSelector);
  const { dashboardList } = useSelector(dashboardListSelector);
  const { dashboardTabList, currentDashboard } = useSelector(dashboardSelector);
  const { spaceList } = useSelector(spaceListSelector);
  const {
    success: dashboardRegisterSuccess,
    id: dashboardInsertId,
    dashboardName,
  } = useSelector(dashboardRegisterSelector);
  const { spaceTabList, currentSpaceId } = useSelector(spaceDetailSelector);
  const {
    id: dashboardRenameId,
    success: dashboardRenameSuccess,
    dashboardName: renamedDashboardName,
  } = useSelector(dashboardRenameSelector);
  const { id: dashboardDeleteId, success: dashboardDeleteSuccess } =
    useSelector(dashboardDeleteSelector);
  const { menu, id: dashboardId } = useSelector(layoutSelector);
  const { success: createSpaceSuccess, name: createName } =
    useSelector(createSpaceSelector);
  const {
    success: updateSpaceSuccess,
    modal: { updateSpaceId, name: updateName, address },
  } = useSelector(updateSpaceSelector);
  const { success: removeSpaceSuccess, spaceId } =
    useSelector(removeSpaceSelector);
  const { loading } = useSelector(dashboardSelector);
  const { service } = useSelector(loginSelector);

  const [disabledTimer, setDisabledTimer] = useState(false);

  useEffect(() => {
    restoreUserService();
  }, [service]);

  const restoreUserService = async () => {
    const { status, result } = await getUpdateDetail();
    if (!status || !result) return;

    const { user_service } = result;
    if (service === user_service) return;

    dispatch(updateUserService(user_service));
  };

  useEffect(() => {
    setDisabledTimer(true);
    const timer = setTimeout(() => {
      setDisabledTimer(false);
    }, 2000);
    if (!loading) return undefined;
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    if (!!token) {
      dispatch(fetchGetDashboardList());
      dispatch(fetchGetSpaceList());
    }
  }, [token]);

  useEffect(() => {
    if (dashboardRegisterSuccess && dashboardInsertId && dashboardName) {
      dispatch(resetLayout());
      dispatch(fetchGetDashboardList());
      dispatch(addDashboardTabList({ id: dashboardInsertId, dashboardName }));
      dispatch(updateCurrentDashboard(dashboardInsertId));
      setCurrent(dashboardInsertId.toString());
      dispatch(resetDashboardRegister());
    } else if (dashboardRenameSuccess && dashboardRenameId) {
      dispatch(resetLayout());
      dispatch(fetchGetDashboardList());
      dispatch(
        renameDashboardTab({
          id: dashboardRenameId,
          name: renamedDashboardName,
        })
      );
      dispatch(resetDashboardRename());
    } else if (dashboardDeleteSuccess) {
      dispatch(resetLayout());
      dispatch(fetchGetDashboardList());
      dispatch(removeDashboardTab(dashboardDeleteId));
      dispatch(updateCurrentDashboard(1));
      dispatch(resetDashboardDelete());
    } else if (createSpaceSuccess) {
      dispatch(fetchGetSpaceList());
    } else if (updateSpaceSuccess) {
      dispatch(fetchGetSpaceList());
      if (spaceTabList.length > 0)
        dispatch(
          renameSpaceTab({
            id: updateSpaceId,
            name: updateName,
            address,
          })
        );
      dispatch(resetUpdateSpace());
    } else if (removeSpaceSuccess) {
      dispatch(fetchGetSpaceList());
      dispatch(removeSpaceTab(spaceId));
      dispatch(resetSpaceRemove());
    }
  }, [
    dashboardRenameSuccess,
    dashboardDeleteSuccess,
    dashboardRegisterSuccess,
    dashboardInsertId,
    dashboardRenameId,
    renamedDashboardName,
    dashboardDeleteId,
    dashboardName,
    updateSpaceSuccess,
    createSpaceSuccess,
    spaceTabList,
    updateSpaceId,
    updateName,
    createName,
    removeSpaceSuccess,
  ]);

  useEffect(() => {
    if (location.pathname.split("/").length < 3) {
      setCurrent("");
    }
  }, [location, location.pathname]);

  useEffect(() => {
    if (location.pathname.split("/")[1] === selectedMenu) {
      setCurrentMenu(selectedMenu);
      setSelectedMenu("");
    }
  }, [
    selectedMenu,
    currentMenu,
    selectedMenu.length,
    currentMenu.length,
    location.pathname,
    location,
  ]);

  useEffect(() => {
    !openSettingModal && setCurrent("");
  }, [openSettingModal]);

  useEffect(() => {
    currentDashboard && setCurrent(`dashboard-${currentDashboard}`);
  }, [currentDashboard]);

  useEffect(() => {
    currentSpaceId &&
      setCurrent(`${location.pathname.split("/")[1]}-${currentSpaceId}`);
  }, [currentSpaceId]);

  useEffect(() => {
    if (!!current && (spaceTabList.length > 0 || dashboardTabList.length > 0)) {
      const id = Number(current.split("-")[1]);
      if (
        !(
          !!spaceTabList.find(
            (element: { id: string }) => Number(element.id) === id
          ) ||
          !!dashboardTabList.find(
            (element: { id: number }) => element.id === id
          )
        )
      ) {
        setCurrent("");
      }
    }
  }, [dashboardTabList, spaceTabList]);

  useEffect(() => {
    const path =
      location.pathname.split("/")[1].indexOf("space") !== -1
        ? "space"
        : location.pathname.split("/")[1];
    current.indexOf(path) === -1 && setCurrent("");
  }, [location.pathname, current]);

  const setOpenSettingModal = useCallback(() => {
    dispatch(updateOpenSettingModal(!openSettingModal));
  }, [openSettingModal]);

  const onClickLink = (value: string) => () => {
    setSelectedMenu(value);
  };

  const onSelectMenu = (e: any) => {
    setCurrent(e.keyPath[0]);
    const id = Number(e.key.split("-")[1]);
    const space = spaceList.filter(
      (item: { id: number; name: string }) => item.id === id
    );
    let newMenus = [];

    if (e.keyPath[1] === "dashboard" && e.key.split("-")[1] !== "add") {
      navigate("/dashboard");
      const dashboard = dashboardList.filter((item) => item.id === id);
      newMenus.push(...dashboardTabList, dashboard[0]);

      const newMenuValues = newMenus;

      const newTabs = newMenus.filter((newMenu, index) => {
        return (
          newMenuValues.findIndex((newMenuValue) => {
            return newMenu.id === newMenuValue.id;
          }) === index
        );
      });

      dispatch(updateDashboardTabList(newTabs));
      dispatch(updateCurrentDashboard(dashboard[0].id));
    } else if (e.keyPath[1] === "space") {
      navigate(`/space/detail`);
      const id = Number(e.key.slice(6));
      newMenus.push(...spaceTabList, space[0]);

      const NewMenuValues = newMenus;
      const newTabs = newMenus.filter((NewMenuValue, index) => {
        return (
          NewMenuValues.findIndex((item2) => {
            return NewMenuValue.id === item2.id;
          }) === index
        );
      });

      dispatch(updateCurrentSpaceId(id));
      dispatch(updateSpaceTabList(newTabs));
    } else if (e.keyPath[1] === "cctv") {
      navigate(`/cctv/detail`);

      newMenus.push(...spaceTabList, space[0]);

      const newMenuValues = newMenus;
      let newTabs = newMenuValues.filter((newMenuValue, index) => {
        return (
          newMenuValues.findIndex((item2) => {
            return newMenuValue.id === item2.id;
          }) === index
        );
      });

      dispatch(updateCurrentSpaceId(id));
      dispatch(updateSpaceTabList(newTabs));
    } else if (e.keyPath[1] === "sales") {
      navigate(`/sales/detail`);

      newMenus.push(...spaceTabList, space[0]);

      const newMenuValues = newMenus;
      let newTabs = newMenuValues.filter((newMenuValue, index) => {
        return (
          newMenuValues.findIndex((item2) => {
            return newMenuValue.id === item2.id;
          }) === index
        );
      });

      dispatch(updateCurrentSpaceId(id));
      dispatch(updateSpaceTabList(newTabs));
    } else if (e.keyPath[0] === "setting") {
      setOpenSettingModal();
    } else {
      setCurrentMenu("");
    }
  };

  const onOpenChange = (openKeys: string[]) => {
    if (location.pathname.split("/")[1] === selectedMenu) return;

    if (openKeys.length > 0) {
      const newKey = openKeys.filter((menu) => menu !== currentMenu)[0];
      setCurrentMenu(newKey);
    } else {
      setCurrentMenu("");
    }
  };

  const items: MenuItem[] = useMemo(() => {
    return [
      getItem(
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/hejhome_logo.png`}
          alt="hejhome_logo"
        />,
        "icon"
      ),
      getItem(
        <NavLink to="/dashboard" onClick={onClickLink("dashboard")}>
          대시보드
        </NavLink>,
        "dashboard",
        <DashboardMenu />,
        dashboardList.map((item, index) => {
          return getItem(
            item.dashboardName,
            `dashboard-${item.id}`,
            <HoverSetting
              menu={`dashboard-${item.id}`}
              key={index}
              name={item.dashboardName}
            />
          );
        }),
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "dashboard" &&
          "color"
        }`
      ),
      getItem(
        service !== "pro" ? (
          <div className="device-history-menu">
            <span className="history-title">CCTV</span>
            <div className="menu-only-pro">
              <span>PRO</span>
            </div>
          </div>
        ) : (
          <NavLink to="/cctv" onClick={onClickLink("cctv")}>
            CCTV
          </NavLink>
        ),
        "cctv",
        <CctvMenu />,
        service !== "pro"
          ? undefined
          : spaceList.map((item) => {
              return getItem(item.name, `cctv-${item.id}`);
            }),
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "cctv" &&
          "color"
        }`,
        service === "basic"
      ),
      getItem(
        service !== "pro" ? (
          <div className="device-history-menu">
            <span className="history-title">매출관리</span>
            <div className="menu-only-pro">
              <span>PRO</span>
            </div>
          </div>
        ) : (
          <NavLink to="/sales" onClick={onClickLink("sales")}>
            매출 관리
          </NavLink>
        ),
        "sales",
        <Sales />,
        service !== "pro"
          ? undefined
          : spaceList.map((item) => {
              return getItem(item.name, `sales-${item.id}`);
            }),
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "sales" &&
          "color"
        }`,
        service === "basic"
      ),
      getItem(
        <NavLink to="/space" onClick={onClickLink("space")}>
          장소 및 기기 관리
        </NavLink>,
        "space",
        <SpaceAndDeviceMenu />,
        spaceList.map((item, index) => {
          return getItem(
            item.name,
            `space-${item.id}`,
            <HoverSetting
              menu={`space-${item.id}`}
              key={index}
              name={item.name}
            />
          );
        }),
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "space" &&
          "color"
        }`
      ),
      getItem(
        <div className="device-history-menu">
          <span className="history-title">히스토리</span>
          <div className="menu-coming-soon">
            <span>곧 만나요</span>
          </div>
        </div>,
        "history",
        <HistoryMenu />,
        undefined,
        "",
        true
      ),
      getItem(
        <NavLink to="/auto" onClick={onClickLink("auto")}>
          <span className="no-child-menu">자동화</span>
        </NavLink>,
        "auto",
        <RoutineMenu />,
        undefined,
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "auto" &&
          "color"
        }`
      ),
      getItem(
        <span className="no-child-menu">설정</span>,
        "setting",
        <SettingMenu />,
        undefined,
        `${
          location.pathname.split("/").length === 2 &&
          location.pathname.split("/")[1] === "setting" &&
          "color"
        }`
      ),
    ];
  }, [
    dashboardList,
    spaceList,
    menu,
    dashboardId,
    dispatch,
    dashboardRegisterSuccess,
    current,
    dashboardInsertId,
    dashboardName,
    dashboardRenameSuccess,
    dashboardDeleteSuccess,
    updateSpaceSuccess,
    createSpaceSuccess,
    spaceTabList,
    updateSpaceId,
    updateName,
    removeSpaceSuccess,
    location.pathname,
  ]);

  return (
    <Menu
      mode="inline"
      style={{ height: "100vh" }}
      items={items}
      selectedKeys={[current]}
      onSelect={onSelectMenu}
      openKeys={[currentMenu]}
      onOpenChange={onOpenChange}
      disabled={disabledTimer}
      triggerSubMenuAction={"click"}
    />
  );
}

export default NavigationMenus;
