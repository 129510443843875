import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  settingServiceInfoSelector,
  updateServiceInfoModalVisible,
} from "../../../store/setting/service-info";
import { getTerm } from "../../../api/join/join";

function ServiceInfoModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { visible, term } = useSelector(settingServiceInfoSelector);
  const [termText, setTermText] = useState({ policy: "", content: "" });
  const onCancel = () => {
    dispatch(updateServiceInfoModalVisible(false));
  };

  useEffect(() => {
    const getInitialState = async () => {
      const { result } = await getTerm(term as string);
      setTermText({
        policy: result.policy,
        content: result.content,
      });
    };
    getInitialState();
  }, [term]);

  return (
    <Modal
      className="service-info-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div dangerouslySetInnerHTML={{ __html: termText.content }} />
    </Modal>
  );
}
export default ServiceInfoModal;
