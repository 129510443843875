import React, { useEffect, useState } from "react";
import { Modal, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  settingAddPaymentSelector,
  updateAddPaymentModalVisible,
} from "../../../../store/setting/payment/add";
import { AppDispatch } from "../../../../store";
import moment, { Moment } from "moment";
import { getBillingKey } from "../../../../api/payment/payment";
import { getRandomNumber } from "../../../../common/utils";
import { updateAddPaymentCompleteCardInfo } from "../../../../store/setting/payment/addComplete";

function AddPaymentModal({
  completedCallback,
}: {
  completedCallback: Function;
}) {
  const { visible } = useSelector(settingAddPaymentSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [enrollResult, setEnrollResult] = useState(true);
  const [editedCard, setEditedCard] = useState(false);
  const [card01, setCard01] = useState("");
  const [card02, setCard02] = useState("");
  const [card03, setCard03] = useState("");
  const [card04, setCard04] = useState("");
  const [editedBirth, setEditedBirth] = useState(false);
  const [birthDay, setBirthDay] = useState<Moment | null>(null);
  const [editedExpMM, setEditedExpMM] = useState(false);
  const [expMM, setExpMM] = useState<Moment | null>(null);
  const [editedExpYY, setEditedExpYY] = useState(false);
  const [expYY, setExpYY] = useState<Moment | null>(null);
  const [editedCardPwd, setEditedCardPwd] = useState(false);
  const [cardPwd, setCardPwd] = useState("");

  const onCancel = () => {
    dispatch(updateAddPaymentModalVisible(false));
  };

  useEffect(() => {
    setResetStat();
  }, [visible]);

  const setResetStat = () => {
    setEditedCard(false);
    setEditedBirth(false);
    setEditedExpMM(false);
    setEditedExpYY(false);
    setEditedCardPwd(false);
    setCard01("");
    setCard02("");
    setCard03("");
    setCard04("");
    setBirthDay(null);
    setExpMM(null);
    setExpYY(null);
    setCardPwd("");
    setEnrollResult(true);
  };

  const isCardOk = () => {
    return (
      card01 !== "" &&
      card01.length === 4 &&
      card02 !== "" &&
      card02.length === 4 &&
      card03 !== "" &&
      card03.length === 4 &&
      card04 !== "" &&
      card04.length === 4
    );
  };

  const isBirthOk = () => {
    return !!birthDay;
  };

  const isExpOk = () => {
    return !!expMM && !!expYY;
  };

  const isCardPwdOk = () => {
    return cardPwd !== "" && cardPwd.length === 2;
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    card: any,
    inputType: string
  ) => {
    const result = event.target.value.replace(/\D/g, "");
    card(result);

    if (inputType === "cardNumber" && !editedCard) setEditedCard(true);

    if (!enrollResult) setEnrollResult(true);
  };

  const isAddPaymentMethod = () => {
    return isCardOk() && isBirthOk() && isExpOk() && isCardPwdOk();
  };

  const checkCardInfo = async () => {
    const { status, result } = await getBillingKey({
      cardNumber: `${card01}${card02}${card03}${card04}`,
      cardExpirationYear: moment(expYY).format("YY"),
      cardExpirationMonth: moment(expMM).format("MM"),
      cardPassword: cardPwd,
      customerIdentityNumber: moment(birthDay).format("YYMMDD"),
      customerKey: getRandomNumber(),
    });

    if (status !== 200) {
      setEnrollResult(false);
    } else {
      dispatch(updateAddPaymentCompleteCardInfo(result));
      completedCallback();
    }
  };

  return (
    <Modal
      className="add-payment-method"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="add-payment-cancel"
          className="add-payment-cancel"
          onClick={onCancel}
        >
          취소
        </Button>,
        <Button
          key="add-payment-ok"
          className="add-payment-ok"
          onClick={checkCardInfo}
          disabled={!isAddPaymentMethod()}
        >
          확인
        </Button>,
      ]}
    >
      <div className="add-payment-method-contents">
        <div className="content-title">
          <p>결제 수단 추가</p>
        </div>
        <div className="content-input">
          <div className="content-input-desc">
            <p>
              카드 정보를 입력해주세요.
              <br />
              본인 명의의 신용/체크 카드만 이용 가능해요.
            </p>
          </div>
          <div className="content-input-items">
            <div className="content-input-items-cardNo">
              <div className="content-input-items-cardNo-title">
                <p>카드번호</p>
              </div>
              <div
                className={
                  !enrollResult
                    ? "content-input-items-cardNo-input-warning"
                    : editedCard
                    ? isCardOk()
                      ? "content-input-items-cardNo-input"
                      : "content-input-items-cardNo-input-warning"
                    : "content-input-items-cardNo-input"
                }
              >
                <input
                  maxLength={4}
                  value={card01}
                  onChange={(e) => {
                    handleChangeInput(e, setCard01, "cardNumber");
                  }}
                />
                <span>-</span>
                <input
                  maxLength={4}
                  value={card02}
                  onChange={(e) => {
                    handleChangeInput(e, setCard02, "cardNumber");
                  }}
                />
                <span>-</span>
                <input
                  maxLength={4}
                  value={card03}
                  onChange={(e) => {
                    handleChangeInput(e, setCard03, "cardNumber");
                  }}
                />
                <span>-</span>
                <input
                  maxLength={4}
                  value={card04}
                  onChange={(e) => {
                    handleChangeInput(e, setCard04, "cardNumber");
                  }}
                />
              </div>
            </div>
            <div className="content-input-items-birth">
              <div className="content-input-items-birth-title">
                <p>생년월일</p>
              </div>
              <div className="content-input-items-birth-content">
                <DatePicker
                  className="birthday-date-picker"
                  format="YYYY년MM월 DD일"
                  placeholder="YYYY년MM월 DD일"
                  showNow={false}
                  value={birthDay}
                  style={{
                    width: "200px",
                    height: "40px",
                    borderRadius: "12px",
                    border: !enrollResult
                      ? "solid 1px #BB3A3A"
                      : editedBirth
                      ? isBirthOk()
                        ? ""
                        : "solid 1px #BB3A3A"
                      : "",
                  }}
                  onChange={(e) => {
                    if (e !== null) setBirthDay(e);
                    if (!editedBirth) setEditedBirth(true);
                    if (!enrollResult) setEnrollResult(true);
                  }}
                />
              </div>
            </div>
            <div className="content-input-items-expDate">
              <div className="content-input-items-expDate-title">
                <p>유효기간</p>
              </div>
              <div className="content-input-items-expDate-title-content">
                <div className="expDate-MM">
                  <DatePicker
                    className="history-date-picker"
                    format="MM"
                    placeholder="MM"
                    picker="month"
                    value={expMM}
                    style={{
                      height: "40px",
                      borderRadius: "12px",
                      border: !enrollResult
                        ? "solid 1px #BB3A3A"
                        : editedExpMM
                        ? isExpOk()
                          ? ""
                          : "solid 1px #BB3A3A"
                        : "",
                    }}
                    onChange={(e) => {
                      if (e !== null) setExpMM(e);
                      if (!editedExpMM) setEditedExpMM(true);
                      if (!enrollResult) setEnrollResult(true);
                    }}
                  />
                </div>
                <div className="expDate-YY">
                  <DatePicker
                    className="history-date-picker"
                    format="YY"
                    placeholder="YY"
                    picker="year"
                    value={expYY}
                    style={{
                      height: "40px",
                      borderRadius: "12px",
                      border: !enrollResult
                        ? "solid 1px #BB3A3A"
                        : editedExpYY
                        ? isExpOk()
                          ? ""
                          : "solid 1px #BB3A3A"
                        : "",
                    }}
                    onChange={(e) => {
                      if (e !== null) setExpYY(e);
                      if (!editedExpYY) setEditedExpYY(true);
                      if (!enrollResult) setEnrollResult(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="content-input-items-pwd">
              <div className="content-input-items-pwd-title">
                <p>비밀번호</p>
              </div>
              <div
                className={
                  !enrollResult
                    ? "content-input-items-pwd-input-warning"
                    : editedCardPwd
                    ? isCardPwdOk()
                      ? "content-input-items-pwd-input"
                      : "content-input-items-pwd-input-warning"
                    : "content-input-items-pwd-input"
                }
              >
                <input
                  maxLength={2}
                  value={cardPwd}
                  placeholder="앞 2자리"
                  type="password"
                  onChange={(e) => {
                    handleChangeInput(e, setCardPwd, "cardPwd");
                    if (!editedCardPwd) setEditedCardPwd(true);
                    if (!enrollResult) setEnrollResult(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {!enrollResult && (
          <div className="content-input-warning">
            <p>카드 정보가 올바르게 입력되지 않았습니다.</p>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddPaymentModal;
