import React, { useEffect } from "react";
import AppRoute from "./AppRoute";
import NavigationMenus from "./layouts/navigation/NavigationMenus";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import SettingModal from "./components/setting/SettingModal";

import { settingSelector } from "./store/setting";
import { useDispatch, useSelector } from "react-redux";
import "././assets/scss/footer.scss";
import { autoLoginSelector, fetchAutoLogin } from "./store/autoLogin";
import { AppDispatch } from "./store";
const { Sider, Content, Footer } = Layout;

function AppContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const { success } = useSelector(autoLoginSelector);
  const autoLogin = localStorage.getItem("hs_login");
  const token = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("email");
  const navigate = useNavigate();

  useEffect(() => {
    if (autoLogin && autoLogin.toUpperCase() === "Y" && userId) {
      dispatch(fetchAutoLogin({ id: userId }));
    } else {
      !token && navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    if (autoLogin && autoLogin.toUpperCase() === "Y" && userId) {
      dispatch(fetchAutoLogin({ id: userId }));
    }
  }, [autoLogin]);

  useEffect(() => {
    if (success) success && navigate("/dashboard");
  }, [success]);

  const { openSettingModal } = useSelector(settingSelector);

  const openServicePolicy = (type: string) => {
    let url = "";
    switch (type) {
      default:
      case "servicePolicy":
        url = "https://goqual.notion.site/f9ed98fec1bd41c68fd860fd754c65e8";
        break;
      case "infoPolicy":
        url = "https://goqual.notion.site/94d85289a74d4169899058ccfe44b5ad";
        break;
    }

    window.open(url, "_blank");
  };

  return (
    <Layout className="" style={{ minHeight: "100%" }}>
      <Layout>
        <Sider trigger={null} collapsible className="layout">
          <NavigationMenus />
        </Sider>
        <Layout className="site-layout">
          <Content>
            <AppRoute />
          </Content>
          <Footer className="site-layout-footer">
            <div className="site-layout-footer-content">
              <div className="site-layout-footer-content-logo">
                <img
                  src={`${process.env.REACT_APP_S3_URL}/1.0/logos/Footer_logo.png`}
                  alt="footer_logo"
                />
              </div>
              <div className="site-layout-footer-content-info">
                <div className="service-name">
                  <p>헤이홈 스퀘어</p>
                </div>
                <div className="service-detail">
                  <div className="service-detail-company">
                    <span>회사 : 주식회사 고퀄</span>
                    <span>사업자 등록번호 : 514-81-92269</span>
                    <span>대표 : 우상범</span>
                    <span>통신판매업신고번호 : 제 2017-서울금천-0737호</span>
                  </div>
                  <div className="service-detail-address">
                    <span>
                      주소 : 08503 서울특별시 금천구 가산디지털1로 165, 1001호,
                      1301호
                    </span>
                    <span>전화번호 : 1644-7098</span>
                    <span>이메일 : square@goqual.com</span>
                  </div>
                  <div className="service-detail-policy-link">
                    <p
                      className="service-policy"
                      onClick={() => {
                        openServicePolicy("servicePolicy");
                      }}
                    >
                      서비스 이용약관
                    </p>
                    <p
                      className="service-info-policy"
                      onClick={() => {
                        openServicePolicy("infoPolicy");
                      }}
                    >
                      개인정보처리방침
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Footer>
        </Layout>
      </Layout>
      <Outlet />
      {openSettingModal ? <SettingModal /> : <></>}
    </Layout>
  );
}

export default AppContainer;
