import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionType, RootState } from "../index";
import { sleep } from "../../common/utils";
import { join } from "../../api/join/join";

type JoinState = {
  loading: boolean;
  error?: string | Error;
  form: JoinForm;
  modalOpen: {
    open: boolean;
    type: string | undefined;
  };
  joinSuccess: boolean;
};

type JoinForm = {
  serviceTerms: boolean;
  email: string | undefined;
  username: string | undefined;
  certificationNumber: number | undefined;
  code: string | undefined;
  password1: string | undefined;
  password2: string | undefined;
  personalTerms: boolean;
  Party3rdTerms: boolean;
  number1: number | undefined;
  number2: number | undefined;
  number3: number | undefined;
  number4: number | undefined;
  number5: number | undefined;
  number6: number | undefined;
  phone: string;
};

const initialState: JoinState = {
  loading: false,
  modalOpen: {
    open: false,
    type: undefined,
  },
  form: {
    email: undefined,
    username: undefined,
    certificationNumber: undefined,
    code: undefined,
    password1: undefined,
    password2: undefined,
    serviceTerms: false,
    personalTerms: false,
    Party3rdTerms: false,
    number1: undefined,
    number2: undefined,
    number3: undefined,
    number4: undefined,
    number5: undefined,
    number6: undefined,
    phone: "",
  },
  joinSuccess: false,
};

export const fetchAccountRegister = createAsyncThunk(
  "join",
  async (body: {
    email: string;
    password: string;
    name: string;
    phone: string;
  }) => {
    await sleep(100);
    const { result } = await join(body);
    return result;
    return true;
  }
);

const slice = createSlice({
  name: "join",
  initialState,
  reducers: {
    updateJoinForm: (
      state: JoinState,
      action: ActionType<JoinState["form"]>
    ) => ({
      ...state,
      form: { ...state.form, ...action.payload },
    }),
    resetForm: (state: JoinState) => ({
      ...state,
      form: initialState.form,
    }),
    openModal: (
      state: JoinState,
      action: ActionType<JoinState["modalOpen"]>
    ) => ({
      ...state,
      modalOpen: { ...state.modalOpen, ...action.payload },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountRegister.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAccountRegister.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.joinSuccess = payload;
    });
    builder.addCase(fetchAccountRegister.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.joinSuccess = false;
    });
  },
});

export const joinSelector = (state: RootState) => state.join;
export const { updateJoinForm, resetForm, openModal } = slice.actions;

export default slice;
