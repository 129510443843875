import React, { useEffect } from "react";
import LoginContainer from "../components/login/LoginContainer";
import { fetchAutoLogin } from "../store/autoLogin";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <LoginContainer />;
}

export default Login;
