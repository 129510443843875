import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  settingPaymentSelector,
  resetPaymentModalVisible,
  updatePaymentModalVisible,
} from "../../../../store/setting/payment";
import { AppDispatch } from "../../../../store";

declare global {
  interface Window {
    PaymentWidget: any;
  }
}

function TossPaymentModal() {
  const { visible } = useSelector(settingPaymentSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const clientKey = "test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq";
  const customerKey = "customer123";
  const paymentWidget = new window.PaymentWidget(clientKey, customerKey); // 결제위젯 초기화
  let paymentMethods: any;
  const onCancel = () => {
    dispatch(updatePaymentModalVisible(false));
  };

  useEffect(() => {
    if (!visible) {
      dispatch(updatePaymentModalVisible(false));
    } else {
      paymentMethods = paymentWidget.renderPaymentMethods(
        "#payment-method",
        15000
      );
    }
  }, [visible]);

  const payTest = async () => {
    paymentWidget
      .requestPayment({
        orderId: "AD8aZDpbzXs4EQa-UkIX611",
        orderName: "월정기 구독",
        successUrl: "http://localhost:3000/dashboard",
        failUrl: "http://localhost:3000/dashboard",
        customerEmail: "customer123@gmail.com",
        customerName: "김토스",
        amount: 15000,
      })
      .then(() => {
        console.log("success");
      })
      .catch((e: any) => {
        console.log("paymentErrorCode", e.code);
        if (e.code === "BELOW_ZERO_AMOUNT")
          paymentMethods = paymentWidget.renderPaymentMethods(
            "#payment-method",
            15000
          );
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="submit" type="primary" onClick={payTest}>
          결제하기
        </Button>,
      ]}
    >
      <div id="payment-method"></div>
    </Modal>
  );
}

export default TossPaymentModal;
