import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type userMembershipConfirmState = {
  visible: boolean;
};

const initialState: userMembershipConfirmState = {
  visible: false,
};

const slice = createSlice({
  name: "setting/userMembership/confirm",
  initialState,
  reducers: {
    resetConfirmModalVisible: (state: userMembershipConfirmState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateConfirmModalVisible: (
      state: userMembershipConfirmState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const settingMembershipConfirmSelector = (state: RootState) =>
  state.setting.userMembership.confirm;

export const { resetConfirmModalVisible, updateConfirmModalVisible } =
  slice.actions;

export default slice;
