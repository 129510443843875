import React from "react";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const NhNMediaExpiredLimitDay_7 = 7;

export type AirPosEventType =
  | "CASHDRAW_OPEN"
  | "CASH"
  | "CARD"
  | "CANCEL_ORDER"
  | "CANCEL_RECEIPT"
  | "SERVICE_ORDER"
  | "DISCOUNT"
  | "POWER_ON"
  | "POWER_OFF"
  | "SALES_OPEN"
  | "SALES_CLOSE"
  | "ETC";

export const AirPosEventTypeConvertor = (eventType: AirPosEventType) => {
  const Events = {
    CASHDRAW_OPEN: "돈통열림",
    CASH: "현금결제",
    CARD: "카드결제",
    CANCEL_ORDER: "주문취소",
    CANCEL_RECEIPT: "결제취소",
    SERVICE_ORDER: "서비스",
    DISCOUNT: "결제할인",
    POWER_ON: "POS시작",
    POWER_OFF: "POS종료",
    SALES_OPEN: "개점",
    SALES_CLOSE: "마감",
    ETC: "기타",
  };

  return Events[eventType];
};

export const handleImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.src = `${process.env.REACT_APP_S3_URL}/1.0/device-icons/default_icon.png`;
};

export const HejHomeLogoSrc = `${process.env.REACT_APP_S3_URL}/1.0/logos/hejhome_logo.png`;
export const datetimeFormat = "YYYY년 MM월 DD일 A hh:mm";
export const dateFormat = "YYYY년 MM월 DD일";
export const timeFormat = "A hh:mm";

export const kelvin_to_rgb = (kelvin: number) => {
  const index = Math.round(((kelvin - 3000) / 3500) * 9);
  return kelvinToRgb[index];
};

export const kelvinToRgb = [
  { r: 255, g: 162, b: 57 },
  { r: 255, g: 182, b: 78 },
  { r: 255, g: 201, b: 100 },
  { r: 255, g: 218, b: 122 },
  { r: 255, g: 234, b: 144 },
  { r: 255, g: 248, b: 167 },
  { r: 248, g: 255, b: 183 },
  { r: 255, g: 255, b: 255 },
  { r: 230, g: 255, b: 255 },
  { r: 200, g: 250, b: 255 },
];

export const modifyUserAgent = (agent: string) => {
  return agent
    .split(";")
    .filter((value) => value.indexOf("=") !== -1)
    .reduce(
      (result, current) => {
        const value = current.split("=");
        result[value[0]] = value[1];
        return result;
      },
      {} as {
        [key: string]: any;
      }
    );
};

export const priceComma = (price: string) => {
  const parts = price.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".") + "원";
};

export const getRandomNumber = () => {
  return self.crypto.randomUUID();
};
