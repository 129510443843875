import { apiGet, apiPost } from "../../common/api";

type updatePasswordBody = {
  oldPassword: string;
  password: string;
};

export type UpdateUserName = {
  name: string;
};

type updateUserNameResponseType = {
  status: number;
  result: boolean;
  message: string;
};

type getUserDetailType = {
  status: number;
  result: {
    id: number;
    user_service: string;
  };
};

type userPlatformsResponseType = {
  status: number;
  result: {
    id: string;
    user_info_id: number;
    sync_platform_id: string;
    uid: string;
    user_id: string;
    created_at: string;
    updated_at: string;
    syncPlatformInfo: {
      id: string;
      platform_type_code: string;
      app_schema: string;
      platform_name: string;
      package_name: string;
    };
  }[];
};

type postUserMembership = {
  status: number;
  success: boolean;
};

type getUserMembershipHistory = {
  status: number;
  result: {
    memberShipChangeSchedule: {
      id: number;
      remainDashboardId: number;
      remainSpaceId: number;
      schedule: string;
    }[];
    memberShipPaymentSchedule: {
      amount: number;
      schedule: string;
    }[];
  };
};

export const getUpdateDetail = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/user/detail`);
    return data as getUserDetailType;
  } catch (e) {
    throw e;
  }
};

export const postUpdateUserName = async (name: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/user/update`, {
      name,
    });
    return data as updateUserNameResponseType;
  } catch (e) {
    throw e;
  }
};

export const postUpdatePassword = async (body: updatePasswordBody) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/user/update/password?updateType=reset`,
      body
    );
    return data as updateUserNameResponseType;
  } catch (e) {
    throw e;
  }
};

export const getUserPlatforms = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/user/platforms`);
    return data as userPlatformsResponseType;
  } catch (e) {
    throw e;
  }
};
export const postMembershipUpdate = async (body: {
  remainDashboardId: number;
  remainSpaceId: number;
  schedule: string;
}) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/user/membership/update`,
      body
    );
    return data as postUserMembership;
  } catch (e) {
    throw e;
  }
};

export const postMembershipChangeCancel = async () => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/user/membership/update/cancel`
    );
    return data as postUserMembership;
  } catch (e) {
    throw e;
  }
};

export const getUseMembershipHistory = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/user/membership/history`);
    return data as getUserMembershipHistory;
  } catch (e) {
    throw e;
  }
};
