import { Button, Form, Input, Select } from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateAirposModalVisible,
  updateAirposStep,
  updateAisposData,
} from "../../../../../store/setting/airpos";
import {
  settingAirposListSelector,
  updateSelectedSpace,
  updateSelectedCamera,
  updatePosNewName,
  updateSettingCamAgain,
} from "../../../../../store/setting/airpos/list";
import {
  fetchGetSpaceList,
  spaceListSelector,
} from "../../../../../store/space/list";
import {
  fetchGetSpaceCameraList,
  settingCameraListSelector,
} from "../../../../../store/setting/airpos/camera";
import { addDeviceInSpace } from "../../../../../api/space/space";
import {
  updateName,
  postPosMappingCamera,
} from "../../../../../api/devices/device";

const { Option } = Select;

function AirposCameraSetting() {
  const { selectedPos, selectedSpace, selectedCamera, posNewName } =
    useSelector(settingAirposListSelector);
  const { spaceList } = useSelector(spaceListSelector);
  const { cameraList: cameraList } = useSelector(settingCameraListSelector);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchGetSpaceList);
  }, []);
  const handleDeviceName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePosNewName({ posNewName: e.target.value }));
  };
  const handleSpace = (value: string) => {
    const newPlace: {
      id: number;
      name: string;
    } = JSON.parse(value);
    dispatch(
      updateSelectedSpace({ id: newPlace.id, space_name: newPlace.name })
    );
    dispatch(fetchGetSpaceCameraList({ spaceId: newPlace.id }));
  };
  const handleCamera = (value: string) => {
    const newCamera: {
      id: number;
      device_name: string;
    } = JSON.parse(value);
    dispatch(
      updateSelectedCamera({
        id: newCamera.id,
        camera_name: newCamera.device_name,
      })
    );
  };

  const handleNext = async () => {
    if (posNewName === "") {
      alert("이름입력");
    } else if (JSON.stringify(selectedSpace) === "{}") {
      alert("장소선택");
    } else if (JSON.stringify(selectedCamera) === "{}") {
      alert("카메라 선택");
    } else {
      const { id: posId, device_name: pos_name } = selectedPos as {
        id: string;
        device_name: string;
      };
      const { id: spaceId } = selectedSpace as { id: string };
      const { id: cameraId } = selectedCamera as { id: string };

      const { result: posMappingCameraResult } = await postPosMappingCamera(
        posId.toString(),
        cameraId.toString()
      );

      if (posMappingCameraResult) {
        await addDeviceInSpace(Number(spaceId), {
          deviceId: Number(posId),
        });

        await updateName(posId, posNewName ? posNewName : pos_name);

        dispatch(updateAirposStep());
        if (posNewName && selectedSpace && selectedCamera) {
          dispatch(
            updateAisposData({ posNewName, selectedSpace, selectedCamera })
          );
          dispatch(updateSettingCamAgain());
        }
      } else {
        alert("* 카메라는 기기당 1대만 지정 할 수 있습니다.");
      }
    }
  };
  return (
    <>
      <h1>설정</h1>
      <div className="pos-data">
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pos_icon.png`}
          alt="pos_icon"
        />
        {selectedPos && selectedPos.device_name}
      </div>
      <div className="pos-camera">
        <div className="content">
          <Form.Item label="이름" className="input-item">
            <Input
              bordered={false}
              className="resign-password-input"
              status=""
              placeholder={"기기의 이름을 입력해주세요."}
              onChange={handleDeviceName}
              maxLength={16}
            />
          </Form.Item>
        </div>
        <div className="content">
          <Form.Item label="장소" className="input-item">
            <Select
              showSearch
              placeholder="기기가 등록 될 장소를 선택해주세요."
              onChange={handleSpace}
              className="pos-select"
            >
              {spaceList &&
                spaceList.map((space, index) => {
                  return (
                    <Option value={JSON.stringify(space)} key={index}>
                      {space.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>
        <div className="content">
          <Form.Item label="카메라" className="input-item">
            <Select
              showSearch
              placeholder="기기와 연동할 카메라를 선택해주세요."
              onChange={handleCamera}
              className="pos-select"
            >
              {cameraList &&
                cameraList.map((camera, index) => {
                  return (
                    <Option value={JSON.stringify(camera)} key={index}>
                      {camera.device_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>
        <p className="warning">* 카메라는 기기당 1대만 지정 할 수 있습니다.</p>
      </div>
      <div className="pos-footer">
        <Button
          onClick={handleNext}
          type="primary"
          disabled={!posNewName && !selectedSpace && !selectedCamera}
        >
          다음
        </Button>
        {selectedPos && (
          <Button onClick={() => dispatch(updateAirposModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default AirposCameraSetting;
