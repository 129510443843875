import { Button, Form, Input, Select } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateToastcamModalVisible,
  updateToastcamStep,
  updateToastcamData,
} from "../../../../../store/setting/toascam";
import {
  settingToastcamListSelector,
  updateSelectedCam,
} from "../../../../../store/setting/toascam/list";
import {
  fetchGetSpaceList,
  spaceListSelector,
} from "../../../../../store/space/list";
import {
  updateSelectedSpace,
  updateSettingCamAgain,
} from "../../../../../store/setting/toascam/list";
import { addDeviceInSpace } from "../../../../../api/space/space";
import { updateName } from "../../../../../api/devices/device";

const { Option } = Select;

function AirposCameraSetting() {
  const [name, setName] = useState("");
  const [space, setSpace] = useState({});
  const [camera] = useState({});
  const { selectedCam } = useSelector(settingToastcamListSelector);
  const { spaceList } = useSelector(spaceListSelector);
  // const { list: cameraList } = useSelector(settingCameraListSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchGetSpaceList);
  }, []);
  const handleDeviceName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (selectedCam)
      dispatch(
        updateSelectedCam({
          id: selectedCam.id,
          name: selectedCam.name,
          newName: e.target.value,
        })
      );
  };
  const handlePlace = (value: string) => {
    const newPlace: {
      id: number;
      name: string;
    } = JSON.parse(value);
    dispatch(updateSelectedSpace({ id: newPlace.id, name: newPlace.name }));
    setSpace({
      ...space,
      id: newPlace.id,
      name: newPlace.name,
    });
  };

  const handleNext = async () => {
    if (!selectedCam) return;

    if (selectedCam && name && JSON.stringify(space) !== "{}" && camera) {
      dispatch(updateToastcamData({ name, space, camera }));
      dispatch(updateSettingCamAgain());

      const { id: spaceId } = space as { id: number };
      const { id: cameraId } = selectedCam;
      const { result: deviceMappintSpaceResult } = await addDeviceInSpace(
        spaceId,
        {
          deviceId: Number(cameraId),
        }
      );
      const { result: updateCameraNameResult } = await updateName(
        cameraId,
        name
      );

      if (deviceMappintSpaceResult && updateCameraNameResult)
        dispatch(updateToastcamStep());
    } else {
      alert("이름 과 장소를 선택해주세요.");
    }
  };
  return (
    <>
      <h1>설정</h1>
      <div className="cam-data">
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pos_icon.png`}
          alt="cam_icon"
        />
        {selectedCam && selectedCam.name}
      </div>
      <div className="cam-camera">
        <div className="content">
          <Form.Item label="이름" className="input-item">
            <Input
              bordered={false}
              className="resign-password-input"
              status=""
              placeholder={"기기의 이름을 입력해주세요."}
              onChange={handleDeviceName}
              maxLength={16}
            />
          </Form.Item>
        </div>
        <div className="content">
          <Form.Item label="장소" className="input-item">
            <Select
              showSearch
              placeholder="기기가 등록 될 장소를 선택해주세요."
              onChange={handlePlace}
              className="pos-select"
            >
              {spaceList &&
                spaceList.map((space, index) => {
                  return (
                    <Option value={JSON.stringify(space)} key={index}>
                      {space.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="cam-footer">
        <Button
          onClick={handleNext}
          type="primary"
          disabled={!name && !space && !camera}
        >
          다음
        </Button>
        {selectedCam && (
          <Button onClick={() => dispatch(updateToastcamModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default AirposCameraSetting;
