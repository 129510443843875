import { apiGet, apiPost } from "../../common/api";

export type DeviceDetailType = {
  id?: number;
  deviceId?: string;
  deviceName?: string;
  pid?: string;
  deviceType?: string;
  platformName?: string;
  status?: {
    [key: string]: any;
  };
  time?: number;
  spaceName?: string;
  categoryId?: number;
  categoryName?: string;
  brandId?: number;
  brandName?: string;
  remoteIndex?: number;
  online?: boolean;
};

export type RemoteType = {
  id: number;
  deviceName: string;
  deviceType: string;
  brandName: string;
};

export type CategoryType = {
  categoryId: number;
  categoryName: string;
  deviceType: string;
  brandList: {
    brandId: number;
    brandName: string;
  }[];
};

type DeviceListResponseType = {
  status: number;
  result: DeviceDetailType[];
};

type DeviceDetailResponseType = {
  status: number;
  result: DeviceDetailType;
};

type RemoteDeviceListResponseType = {
  status: number;
  result: RemoteType[];
};

type CategoryListResponseType = {
  status: number;
  result: CategoryType[];
};

type ToastcamMappingPosResponseType = {
  result: boolean;
  message?: string;
};

type DeviceControlResponseType = {
  status: number;
  result: boolean;
  message: string;
};

export type HistoryDetailType = {
  result: string;
  table: number;
  deviceType: string;
  eventCode: string;
  eventTime: number;
  eventValue: number;
};

type HistoryDetailResponseType = {
  status: number;
  result: {
    result: string;
    table: number;
    deviceType: string;
    eventCode: string;
    eventTime: number;
    eventValue: number;
  }[];
};

export const getDeviceListByUser = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/devices`);
    return data as DeviceListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getDeviceDetailByDeviceId = async (id: number, token?: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/devices/${id}`,
      token && {
        Authorization: `${token}`,
      }
    );
    return data as DeviceDetailResponseType;
  } catch (e) {
    throw e;
  }
};

export const getRemoteListByDeviceId = async (id: number, token?: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/devices/${id}/remotes`,
      token && {
        Authorization: `${token}`,
      }
    );
    return data as RemoteDeviceListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getCategoryListByDeviceId = async (id: number, token?: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/devices/${id}/remote/categories`,
      token && {
        Authorization: `${token}`,
      }
    );
    return data as CategoryListResponseType;
  } catch (e) {
    throw e;
  }
};

export const postAddRemoteDevice = async (
  id: number,
  categoryId: number,
  brandId: number,
  token?: string
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/devices/remotes/${id}`,
      { categoryId, brandId },
      token && {
        Authorization: `${token}`,
      }
    );
    return data as DeviceControlResponseType;
  } catch (e) {
    throw e;
  }
};

export const updateName = async (deviceId: string, name: string) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/devices/update/name/${deviceId}`,
      { name }
    );
    return data as ToastcamMappingPosResponseType;
  } catch (e) {
    throw e;
  }
};

export const postPosMappingCamera = async (posId: string, cameraId: string) => {
  try {
    const body = { posId: posId, cameraId: cameraId };
    const { data } = await apiPost(`/v1.0/openapi/airpos/mapping/camera`, body);
    return data as ToastcamMappingPosResponseType;
  } catch (e) {
    throw e;
  }
};

export const postDeviceControlByDeviceId = async (
  deviceId: number,
  body: {
    [key: string]: any;
  },
  accessToken?: string
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/devices/control/${deviceId}`,
      { requirements: body },
      accessToken && {
        Authorization: `${accessToken}`,
      }
    );
    return data as DeviceControlResponseType;
  } catch (e) {
    throw e;
  }
};
export const getDeviceHistoryDetail = async (
  deviceId: string,
  startTimeStamp: number,
  endTimeStamp: number,
  eventCode?: string
) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/devices/${deviceId}/history/detail?startTimeStamp=${startTimeStamp}&endTimeStamp=${endTimeStamp}${
        eventCode ? `&eventCode=${eventCode}` : ""
      }`
    );
    return data as HistoryDetailResponseType;
  } catch (e) {
    throw e;
  }
};
