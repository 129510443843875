import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  settingPaymentHistorySelector,
  updatePaymentHistoryModalVisible,
} from "../../../../store/setting/payment/history";
import { AppDispatch } from "../../../../store";
import { getUserPaymentHistoryList } from "../../../../api/payment/payment";
import moment from "moment";

type paymentHistory = {
  id: number;
  orderId: string;
  orderName: string;
  created_at: string;
};

function ToastCamModal() {
  const { visible } = useSelector(settingPaymentHistorySelector);

  const dispatch = useDispatch<AppDispatch>();

  const [paymentHistory, setPaymentHistory] = useState<paymentHistory[]>([]);
  const [searchMonth, setSearchMonth] = useState(moment().format("YYYY-MM"));
  const [months] = useState([
    {
      title: moment().format("YYYY년 MM월"),
      value: moment().format("YYYY-MM"),
    },
    {
      title: moment().subtract(1, "months").format("YYYY년 MM월"),
      value: moment().subtract(1, "months").format("YYYY-MM"),
    },
    {
      title: moment().subtract(2, "months").format("YYYY년 MM월"),
      value: moment().subtract(2, "months").format("YYYY-MM"),
    },
  ]);

  useEffect(() => {
    if (visible) getPaymentHistory();
  }, [visible]);

  useEffect(() => {
    getPaymentHistory();
  }, [searchMonth]);

  const getPaymentHistory = async () => {
    const { status, result } = await getUserPaymentHistoryList(searchMonth);
    if (!status) return;

    const sortedList = result.reduce((acc: any, obj: any) => {
      const { created_at } = obj;
      const date = moment(created_at).format("MM월 DD일 (dddd)");
      acc[date] = acc[date] ?? [];
      obj.title = moment(created_at).format("MM월 DD일 (dddd)");
      acc[date].push(obj);
      return acc;
    }, []);

    setPaymentHistory(sortedList);
  };
  const onCancel = () => {
    dispatch(updatePaymentHistoryModalVisible(false));
  };

  const onChangeSearchMonth = (e: any) => {
    setSearchMonth(e.target.value);
  };

  const HistoryComponent = ({ item }: { item: any }) => {
    return item.map(
      (
        detail: { data: string; orderName: string; amount: string },
        index: number
      ) => {
        const { data, orderName, amount } = detail;
        if (!data) return null;

        return (
          <div className="history-items-content" key={index}>
            <div className="history-items-type">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/user/PaymentHistory.png`}
                alt="paymentHistory_icon"
              />
              <p>{orderName}</p>
            </div>
            <div className="history-items-amount">
              <p>{amount}</p>
            </div>
          </div>
        );
      }
    );
  };

  return (
    <Modal
      className="payment-history-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <h1>결제 내역</h1>
      <div className="payment-history-date-list">
        <div>
          <LeftOutlined />
        </div>
        <div className="payment-history-date-picker">
          <select
            onChange={(e) => {
              onChangeSearchMonth(e);
            }}
          >
            {/*<option>2023년 1월</option>*/}
            {months.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <RightOutlined />
        </div>
      </div>

      {Object.keys(paymentHistory).length === 0 ? (
        <div className="payment-history-items">
          <div className="history-items-date">
            <p>데이터가 없습니다.</p>
          </div>
        </div>
      ) : (
        Object.keys(paymentHistory).map((historyKey, index) => {
          let item = paymentHistory[historyKey as keyof typeof paymentHistory];
          return (
            <div className="payment-history-items" key={index}>
              <div className="history-items-date">
                <p>{historyKey}</p>
                <HistoryComponent item={item} />
              </div>
            </div>
          );
        })
      )}
    </Modal>
  );
}

export default ToastCamModal;
