import React, { useEffect, useState } from "react";
import { Button, Divider, List } from "antd";
import { ChangePayMethodPopup } from "./ChangePayMethodPopup";
import { getPaymentMethodLists } from "../../../api/payment/payment";

function PaymentManage() {
  const data = [
    {
      plan: "Hejhome Premium",
      date: "2022년 6월 30일",
      money: "100,000원",
    },
    {
      plan: "Hejhome Premium",
      date: "2022년 6월 30일",
      money: "100,000원",
    },
    {
      plan: "Hejhome Premium",
      date: "2022년 6월 30일",
      money: "100,000원",
    },
    {
      plan: "Hejhome Premium",
      date: "2022년 6월 30일",
      money: "100,000원",
    },
  ];

  const [showChangePayMethodPopup, setShowChangePayMethodPopup] =
    useState(false);

  const showChangePayMethod = () => {
    setShowChangePayMethodPopup(true);
  };

  const onClickChangePayMethodCancel = () => {
    setShowChangePayMethodPopup(false);
  };

  const onClickChangePayMethodOk = () => {
    setShowChangePayMethodPopup(false);
  };
  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>결제 관리</h1>
      </div>
      <div className="setting-content">
        <p>현재 결제 정보</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/plan_icon.png`}
            alt="plan_icon"
          />
          <div className="setting-info">
            <h1>요금제</h1>
            <p>Hejhome Premium (다음 결제일 : 2022년 10월 10일)</p>
          </div>
          <Button type="default">요금제 변경</Button>
        </div>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/dollor_icon.png`}
            alt="dollar_icon"
          />
          <div className="setting-info">
            <h1>지불 수단</h1>
            <p>현대 카드 0123</p>
          </div>
          <Button type="default" onClick={showChangePayMethod}>
            지불 수단 변경
          </Button>
        </div>
        <Divider />
      </div>
      <div className="setting-content">
        <p>충전 잔액</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/alarm_talk.png`}
            alt="security_icon"
          />
          <div className="setting-info">
            <h1>알림 톡 개수</h1>
            <p>234건 남음</p>
          </div>
          <Button type="primary">충전</Button>
        </div>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/sms_icon.png`}
            alt="security_icon"
          />
          <div className="setting-info">
            <h1>SMS 개수</h1>
            <p>234건 남음</p>
          </div>
          <Button type="primary">충전</Button>
        </div>
        <Divider />
      </div>
      <div className="setting-content">
        <p>결제 잔액</p>
        <List
          itemLayout={"horizontal"}
          dataSource={data}
          renderItem={(item) => (
            <List.Item style={{ padding: "16px 0" }}>
              <p>{item.plan}</p>
              <p>{item.date}</p>
              <p>{item.money}</p>
              <a>상세 내역</a>
            </List.Item>
          )}
        />
      </div>

      {showChangePayMethodPopup && (
        <ChangePayMethodPopup
          visible={showChangePayMethodPopup}
          onClickOk={onClickChangePayMethodOk}
          onClickCancel={onClickChangePayMethodCancel}
        />
      )}
    </div>
  );
}
export default PaymentManage;
