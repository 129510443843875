import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store";
import { updateAirposStep } from "../../../../../store/setting/airpos";
import { checkAirPosAccount } from "../../../../../api/airPos/airPos";
const md5 = require("md5");

function AirposLogin({
  id,
  password,
  setId,
  setPassword,
}: {
  id: string;
  password: string;
  setId: any;
  setPassword: any;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const [invalid, setInvalid] = useState(false);

  const checkAccount = async () => {
    const { result } = await checkAirPosAccount(id, md5(password));
    if (result) {
      dispatch(updateAirposStep());
    } else {
      setInvalid(true);
    }
  };

  return (
    <>
      <h1>로그인</h1>
      <p>POS/키오스크 설치 시 제공 받은 계정 정보를 입력해주세요.</p>
      <p>
        계정 정보가 기억나지 않을 시,
        {/*<a href="https://google.com/" target="_blank" rel="noopener noreferrer">*/}
        {/*  <u>에어포스 고객센터</u>*/}
        {/*</a>*/}
        에어포스 고객센터 로 문의 바랍니다.
      </p>

      <div className="login-box">
        <div className="login-item">
          <Form.Item
            label="아이디"
            className={invalid ? "input-item-error" : "input-item"}
          >
            <Input
              bordered={false}
              className={
                invalid
                  ? "resign-password-input-error"
                  : "resign-password-input"
              }
              status="error"
              defaultValue={id}
              placeholder={"아이디"}
              onChange={(e) => {
                setInvalid(false);
                setId(e.target.value);
              }}
            />
          </Form.Item>
          {invalid && (
            <div className="login-error">올바르지 않은 이메일 형식입니다.</div>
          )}
        </div>
        <Form.Item
          label="비밀번호"
          className={invalid ? "input-item-error" : "input-item"}
        >
          <Input.Password
            bordered={false}
            className={
              invalid ? "resign-password-input-error" : "resign-password-input"
            }
            defaultValue={password}
            placeholder={"PW 규칙"}
            status=""
            onChange={(e) => {
              setInvalid(false);
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
        {invalid && (
          <div className="login-error">
            비밀번호 또는 이메일이 올바르지 않습니다.
          </div>
        )}
      </div>
      <div className="pos-footer">
        <Button type="primary" onClick={() => checkAccount()}>
          로그인
        </Button>
      </div>
    </>
  );
}

export default AirposLogin;
