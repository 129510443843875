import React, { useEffect } from "react";
import MultiViewContainer from "../components/multi-view/MultiView";
import { useNavigate } from "react-router-dom";

function MultiView() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <MultiViewContainer />;
}

export default MultiView;
