import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { postUpdatePassword } from "../../../../api/user/user";

type updatePasswordBody = {
  oldPassword: string;
  password: string;
};

type UpdateState = {
  loading: boolean;
  success: boolean;
  error?: string | Error;
};

const initialState: UpdateState = {
  loading: false,
  success: false,
  error: undefined,
};

export const fetchUpdateAccountPassword = createAsyncThunk(
  "setting/account/update/password",
  async (body: updatePasswordBody) => {
    await sleep(100);
    const { result } = await postUpdatePassword(body);
    return result;
  }
);

const slice = createSlice({
  name: "setting/account/update/password",
  initialState,
  reducers: {
    resetUpdateAccountPassword: (state: UpdateState) => ({
      ...state,
      success: initialState.success,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateAccountPassword.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchUpdateAccountPassword.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = payload;
        state.error = undefined;
      }
    );
    builder.addCase(fetchUpdateAccountPassword.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const settingAccountUpdatePasswordSelector = (state: RootState) =>
  state.setting.account.updatePassword;

export const { resetUpdateAccountPassword } = slice.actions;

export default slice;
