export function emailRegex(email: string) {
  const regex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i);
  return regex.test(email);
}

export function passwordRegex(password: string) {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/;
  return regex.test(password);
}

export function generateKey(pre: string) {
  return `${pre}_${new Date().getTime()}`;
}
