import React, { useEffect } from "react";
import { useTitle } from "ahooks";
import PanelContainer from "../components/panels/Panel";
import { useNavigate } from "react-router-dom";
function Panel() {
  useTitle("Panel | 헤이홈 스퀘어");
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <div>
      <PanelContainer />
    </div>
  );
}

export default Panel;
