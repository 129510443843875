import React from "react";

class HistoryMenu extends React.Component {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.63.833h.74c1.369 0 2.447 0 3.315.071.885.073 1.625.223 2.296.565a5.834 5.834 0 0 1 2.55 2.55c.342.671.492 1.41.565 2.296.07.868.07 1.946.07 3.315v.74c0 1.37 0 2.448-.07 3.315-.073.886-.223 1.625-.565 2.297a5.833 5.833 0 0 1-2.55 2.549c-.235.12-.48.217-.74.296-.926.28-2.059.326-3.569.337-.409.003-.854.003-1.337.003h-.67c-.483 0-.928 0-1.338-.003-1.51-.01-2.642-.056-3.569-.337a4.476 4.476 0 0 1-.74-.296 5.833 5.833 0 0 1-2.549-2.55c-.342-.671-.493-1.41-.565-2.296-.07-.867-.07-1.946-.07-3.315v-.74c0-1.369 0-2.447.07-3.315.072-.885.223-1.625.565-2.296a5.833 5.833 0 0 1 2.55-2.55c.671-.342 1.41-.492 2.296-.565.867-.07 1.946-.07 3.315-.07ZM6.45 2.566c-.782.063-1.277.186-1.675.388a4.167 4.167 0 0 0-1.82 1.821c-.204.398-.326.893-.39 1.676-.064.792-.065 1.802-.065 3.216v.667c0 1.413 0 2.424.065 3.216.064.782.186 1.277.39 1.675.29.57.705 1.062 1.212 1.442v-5.834c0-.46.373-.833.833-.833h2.5V5c0-.46.373-.833.833-.833h3.334c.46 0 .833.373.833.833v7.5H15c.46 0 .833.373.833.834v3.333c.507-.38.923-.872 1.213-1.442.202-.398.325-.893.389-1.675.064-.792.065-1.803.065-3.216v-.667c0-1.414 0-2.424-.065-3.216-.064-.783-.187-1.278-.39-1.676a4.167 4.167 0 0 0-1.82-1.82c-.398-.203-.893-.326-1.676-.39-.792-.064-1.802-.065-3.216-.065h-.666c-1.414 0-2.425 0-3.217.066Zm7.717 14.798v-3.197H12.5v3.318c.697-.017 1.23-.052 1.667-.121Zm-3.334.136V5.834H9.167V17.5h1.666ZM7.5 17.485v-5.818H5.833v5.697c.436.069.97.104 1.667.121Z"
          fill="#989691"
        />
      </svg>
    );
  }
}

export default HistoryMenu;
