import React from "react";

class RoutineMenu extends React.Component {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.63.833h.74c1.369 0 2.447 0 3.315.071.885.073 1.625.223 2.296.565a5.834 5.834 0 0 1 2.55 2.55c.342.671.492 1.41.565 2.296.07.868.07 1.946.07 3.315v2.87a.833.833 0 0 1-.244.59l-5.833 5.833a.834.834 0 0 1-.59.244H9.63c-1.368 0-2.447 0-3.314-.071-.886-.072-1.625-.223-2.297-.565a5.833 5.833 0 0 1-2.549-2.55c-.342-.671-.493-1.41-.565-2.296-.07-.867-.07-1.946-.07-3.315v-.74c0-1.369 0-2.447.07-3.315.072-.885.223-1.625.565-2.296a5.833 5.833 0 0 1 2.55-2.55c.671-.342 1.41-.492 2.296-.565.867-.07 1.946-.07 3.315-.07ZM6.45 2.566c-.782.063-1.277.186-1.675.388a4.167 4.167 0 0 0-1.82 1.821c-.204.398-.326.893-.39 1.676-.064.792-.065 1.802-.065 3.216v.667c0 1.413 0 2.424.065 3.216.064.782.186 1.277.39 1.675a4.167 4.167 0 0 0 1.82 1.821c.398.203.893.325 1.675.389.792.065 1.803.065 3.217.065h2v-2.365c0-.44 0-.819.025-1.13.027-.33.086-.658.247-.973a2.5 2.5 0 0 1 1.093-1.093c.315-.16.643-.22.972-.247.312-.025.691-.025 1.13-.025H17.5v-2c0-1.414 0-2.424-.065-3.216-.064-.783-.187-1.278-.39-1.676a4.167 4.167 0 0 0-1.82-1.82c-.398-.203-.893-.326-1.676-.39-.792-.064-1.802-.065-3.216-.065h-.666c-1.414 0-2.425 0-3.217.066Zm9.871 10.768h-1.154c-.48 0-.791 0-1.027.02-.227.018-.31.05-.352.07a.833.833 0 0 0-.364.365c-.02.04-.052.125-.07.351-.02.236-.02.546-.02 1.027v1.155l2.987-2.988Zm-3.404-7.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM10 7.083a2.917 2.917 0 1 1 1.529 2.566l-1.88 1.88a2.917 2.917 0 1 1-1.179-1.178l1.88-1.88A2.904 2.904 0 0 1 10 7.083Zm-2.917 4.583a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
          fill="#989691"
        />
      </svg>
    );
  }
}

export default RoutineMenu;
