import { apiGet, apiPost } from "../../common/api";

export type DashboardDeviceStatusType = {
  id: number;
  deviceId: number;
  device_id?: string;
  deviceName: string;
  deviceType: string;
  status: {
    [key: string]: any;
  };
  time?: number;
  layout: {
    w: number;
    h: number;
  };
  widgetName: string;
  spaceName: string;
  categoryId?: number;
  categoryName?: string;
  brandId?: number;
  brandName?: string;
  remoteIndex?: number;
  online?: boolean;
};

type DeviceListType = {
  status: number;
  result: DashboardDeviceStatusType[];
};

type DashboardCreateResponseType = {
  status: number;
  result: {
    id: number;
    dashboardName: string;
    success: boolean;
  };
};

type BooleanResponseType = {
  status: number;
  result: boolean;
};

export const getDeviceStatusList = async (dashboardId: number) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/dashboard/${dashboardId}/devices`
    );
    return data as DeviceListType;
  } catch (e) {
    throw e;
  }
};

export const registerDashboard = async (name: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/dashboard`, { name });
    return data as DashboardCreateResponseType;
  } catch (e) {
    throw e;
  }
};

export const updateDashboardById = async (id: number, name: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/dashboard/${id}/update`, {
      name,
    });
    return data as BooleanResponseType;
  } catch (e) {
    throw e;
  }
};

export const deleteDashboardById = async (id: number) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/dashboard/${id}/delete`);
    return data as BooleanResponseType;
  } catch (e) {
    throw e;
  }
};

export const deleteWidgetListInDashboard = async (
  id: number,
  widgetIdList: number[]
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/dashboard/${id}/widgets/delete`,
      { widgetIdList }
    );
    return data as BooleanResponseType;
  } catch (e) {
    throw e;
  }
};

export const remoteWidgetDevice = async (
  dashboardId: number,
  widgetId: number,
  body: {
    [key: string]: any;
  }
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/dashboard/${dashboardId}/widgets/control/${widgetId}`,
      { requirements: body }
    );
    return data as BooleanResponseType;
  } catch (e) {
    throw e;
  }
};
