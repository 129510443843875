import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, updateJoinForm } from "../../../store/join";
import useQuery from "../../../utils/useQuery";
import PasswordForm from "../../common/password-form/PasswordForm";
import { Button, Input } from "antd";
import { getUserAuthInfo } from "../../../api/join/join";

function JoinStep4() {
  const dispatch = useDispatch();
  const { form } = useSelector(joinSelector);
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    if (
      !form.serviceTerms ||
      !form.personalTerms ||
      // !form.Party3rdTerms ||
      !form.email ||
      !form.certificationNumber
    )
      navigate("/login/signup?step=1");

    const imp_uid = query.get("imp_uid");
    if (imp_uid) getImpUidInfo(imp_uid);
  }, []);

  const onNextClick = useCallback(async () => {
    navigate(`/login/signup?step=${step + 1}`);
  }, []);

  const AuthUser = async () => {
    const IMP = window.IMP; // 생략 가능
    IMP.init("imp14436053"); // 예: imp00000000

    IMP.certification(
      {
        m_redirect_url: "https://hejhomesquare.life/login/signup?step=4",
        popup: false,
      },
      async function (rsp: any) {
        if (rsp.success) {
          const { imp_uid } = rsp;
          if (imp_uid) getImpUidInfo(imp_uid);
        } else {
          console.log("FAIL", rsp);
        }
      }
    );
  };

  const getImpUidInfo = async (imp_uid: string) => {
    if (!imp_uid) return;

    const { result, data } = await getUserAuthInfo(imp_uid as string);
    if (!result) navigate("/login/signup?step=1");
    const { phone } = data;
    dispatch(updateJoinForm({ phone: phone }));
  };

  return (
    <div>
      <div className="hejhome-phone-auth">
        <div className="hejhome-phone-auth-field">
          <div className="hejhome-phone-auth-field-phone">
            <label>본인인증</label>
            <Input
              type={"text"}
              autoComplete="off"
              placeholder="핸드폰 번호"
              value={form.phone}
              disabled={true}
            />
          </div>
          <div className="hejhome-phone-auth-field-button">
            <Button onClick={AuthUser}>인증</Button>
          </div>
        </div>
      </div>
      <PasswordForm />
      <div className="join-footer">
        <Button
          type="primary"
          disabled={
            !(form.password1 && form.password2) ||
            form.password1 !== form.password2 ||
            form.phone === ""
          }
          onClick={onNextClick}
        >
          다음
        </Button>
      </div>
    </div>
  );
}

export default JoinStep4;
