import { apiGet, apiPost } from "../../common/api";

type getBillingKeyResult = {
  status: number;
  result: {
    mId: string;
    customerKey: string;
    authenticatedAt: string;
    method: string;
    billingKey: string;
    cardCompany: string;
    cardNumber: string;
    card: {
      company: string;
      issuerCode: string;
      acquirerCode: string;
      number: string;
      cardType: string;
      ownerType: string;
    };
  }[];
};

type getPaymentMethodResult = {
  status: number;
  success: boolean;
  result: {
    id: string;
    goqual_user_info_id: string;
    cardNumber: string;
    cardCompany: string;
    customerKey: string;
    priority: number;
    isValid: string;
    created_at: string;
    updated_at: string;
  }[];
};

type getPaymentMethodOneResult = {
  status: number;
  success: boolean;
  result: {
    id: string;
    goqual_user_info_id: string;
    cardNumber: string;
    cardCompany: string;
    customerKey: string;
    priority: number;
    isValid: string;
    created_at: string;
    updated_at: string;
  };
};

type postUpdatePriorityResult = {
  status: number;
  success: boolean;
  result: boolean;
};

type getUserPaymentHistoryResult = {
  status: number;
  result: {
    id: number;
    orderId: string;
    orderName: string;
    created_at: string;
  }[];
};

export const getBillingKey = async (body: {
  cardNumber: string;
  cardExpirationYear: string;
  cardExpirationMonth: string;
  cardPassword: string;
  customerIdentityNumber: string;
  customerKey: string;
}) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/user/payment/billingkey`,
      body
    );
    return data as getBillingKeyResult;
  } catch (e) {
    return { status: 400, result: {} };
    throw e;
  }
};

export const getPaymentMethodLists = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/user/paymentMethods`);
    return data as getPaymentMethodResult;
  } catch (e) {
    throw e;
  }
};

export const postUpdatePriority = async (body: {
  id: number;
  isUse?: string;
  isMain?: string;
}) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/user/payment/priority`, body);
    return data as postUpdatePriorityResult;
  } catch (e) {
    throw e;
  }
};

export const getUserPaymentHistoryList = async (month: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/user/payment/history?month=${month}`
    );
    return data as getUserPaymentHistoryResult;
  } catch (e) {
    throw e;
  }
};

export const postDeletePaymentMethod = async (paymentMethodId: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/user/delete/paymentMethod`, {
      id: paymentMethodId,
    });
    return data as getPaymentMethodResult;
  } catch (e) {
    throw e;
  }
};

export const getPaymentMethodOne = async (paymentMethodId: number) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/user/paymentMethods/${paymentMethodId}`
    );
    return data as getPaymentMethodOneResult;
  } catch (e) {
    throw e;
  }
};
