import React from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { updateLayout, layoutSelector } from "../../store/layout";
import { updateDashboardModalVisible } from "../../store/dashboard/register";
import {
  updateDashboardRenameModalId,
  updateDashboardRenameModalName,
  updateDashboardRenameModalVisible,
} from "../../store/dashboard/rename";
import {
  updateDashboardDeleteId,
  updateDashboardDeleteModalVisible,
} from "../../store/dashboard/delete";

function LayoutDashboardSettingPopover({
  id,
  name,
}: {
  id: number;
  name: string;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { menu } = useSelector(layoutSelector);

  const onEditClick = (menu: string) => () => {
    dispatch(updateLayout(menu));
    if (menu === "dashboard_add") {
      dispatch(updateDashboardModalVisible(true));
    } else if (menu == "dashboard_update") {
      dispatch(updateDashboardRenameModalVisible(true));
      dispatch(updateDashboardRenameModalId(id));
      dispatch(updateDashboardRenameModalName(name));
    } else if (menu === "dashboard_remove") {
      dispatch(updateDashboardDeleteModalVisible(true));
      dispatch(updateDashboardDeleteId(id));
    }
  };

  return (
    <div className="layout-setting-list">
      <Button
        onClick={onEditClick("dashboard_add")}
        className={menu === "dashboard_add" ? "active" : ""}
      >
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/new_space.png`}
          alt="new_space"
        />
        <span>새 대시보드 추가</span>
      </Button>
      <Button
        onClick={onEditClick("dashboard_update")}
        className={menu === "dashboard_update" ? "active" : ""}
      >
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/edit_black.png`}
          alt="edit_black"
        />
        대시보드 이름 변경
      </Button>
      {/*<Button*/}
      {/*  onClick={onEditClick("dashboard_duplicate")}*/}
      {/*  className={menu === "dashboard_duplicate" ? "active" : ""}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/duplicate_icon.png`}*/}
      {/*    alt="edit_black"*/}
      {/*  />*/}
      {/*  대시보드 복제*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  onClick={onEditClick("dashboard_member")}*/}
      {/*  className={menu === "dashboard_member" ? "active" : ""}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/member_icon.png`}*/}
      {/*    alt="edit_black"*/}
      {/*  />*/}
      {/*  대시보드 구성원*/}
      {/*</Button>*/}
      <Button
        onClick={onEditClick("dashboard_remove")}
        className={menu === "dashboard_remove" ? "active" : ""}
      >
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/trash_red.png`}
          alt="edit_black"
        />
        대시보드 삭제
      </Button>
    </div>
  );
}
export default LayoutDashboardSettingPopover;
